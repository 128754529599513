import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationsEN from "./en/en.json";
import translationsHE from "./he/he.json";

const resources = {
  en: {
    translation: translationsEN,
  },
  he: {
    translation: translationsHE,
  },
};

i18n.use(initReactI18next).use(LanguageDetector).init({
  lng: "he", // set the default language
  fallbackLng: "en", // set the fallback language
  resources: resources,
});

export default i18n;
