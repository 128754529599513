import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useComments } from '../../hooks/use-comments';
import {
  Avatar,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  TextField,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import './comments.css';
import {
  AddComment,
  DeleteComment,
} from '../../dbOps/comments/commentsCollection';

function Comments({ id }: any) {
  const [comment, setComment] = React.useState('');
  const { t } = useTranslation();
  const user = useSelector((state: any) => state.firebase.auth);
  const comments = useComments(id!);

  const handleAddComment = () => {
    AddComment(id!, user, comment);
  };
  const handleDeleteComment = (docId: string) => {
    DeleteComment(id!, docId);
  };
  return (
    <div>
      <h2>{t('Comments')}:</h2>
      <TextField
        fullWidth
        autoComplete="off"
        label={t('Comment')}
        id="fullWidth"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setComment(event.target.value);
        }}
      />
      <Button onClick={handleAddComment}>{t('Add')}</Button>
      <Paper className="comment">
        <Grid
          className="comment-container"
          display="contents"
          container
          wrap="nowrap"
          spacing={2}
        >
          {comments
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            ?.filter((e, i) => e.creator != null)
            .map((comment: any, index: number) => (
              <Grid item key={index}>
                <Avatar
                  alt={comment.creator}
                  src={comment?.avatar}
                  style={{ float: 'right' }}
                />
                <h4 className="comment-creator">{comment.creator}</h4>
                <p className="comment-text">{comment.text}</p>
                <p className="comment-time">
                  <IconButton onClick={() => handleDeleteComment(comment.id)}>
                    <DeleteIcon></DeleteIcon>
                  </IconButton>
                  {moment(comment.created?.toDate()).fromNow()}{' '}
                </p>
                <Divider variant="fullWidth" style={{ margin: '30px 0' }} />
              </Grid>
            ))}
        </Grid>
      </Paper>
    </div>
  );
}

export default Comments;
