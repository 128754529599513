import firebase from "firebase/compat/app";

import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/database";
import "firebase/compat/auth";
import { getDatabase } from "firebase/database";
const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyDBVCENJYyUldOugncwhb5HsTDjI_8vt3Y",
  authDomain: "grade-boost.com",
  databaseURL:
    "https://math-practice-2350d-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "math-practice-2350d",
  storageBucket: "math-practice-2350d.appspot.com",
  messagingSenderId: "424536288935",
  appId: "1:424536288935:web:9f583f20dfe75aa09fb582",
  measurementId: "G-QVED3HTP0N",
});

const db = firebaseApp.firestore();
const rtdb =getDatabase();

const fieldValue = firebase.firestore.FieldValue;
const auth = firebase.auth(firebaseApp);
const storage = firebase.storage();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });

const signInWithGoogle = () => auth.signInWithPopup(provider);
const signOutGoogle = () => auth.signOut();
async function getUserIdToken() {
  return firebase.auth()?.currentUser?.getIdTokenResult();
}
auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

auth.onAuthStateChanged(function (user) {
  if (user) {
    // User is signed in.
    console.log(user);
  } else {
    // No user is signed in.
    console.log("User is not signed in.");
  }
});

export {
  db,
  auth,
  storage,
  signInWithGoogle,
  firebaseApp,
  signOutGoogle,
  fieldValue,
  firebase,
  getUserIdToken,
  rtdb
};
