import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  direction: 'rtl',
  palette: {
    mode: 'light',

    primary: {
      main: '#003060',
    },
  },
});

export default theme;
