import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { RootState } from "../store";

export const useGradeAssets = (gradeId: string) => {
  const user = useSelector((state: any) => state.firebase.auth);

  useFirestoreConnect(() => {
    if (!user?.uid) return [];
    if (gradeId === "") return [];

    return [
      {
        collection: "grades",
        doc: gradeId,
        subcollections: [
          {
            collection: "assets",
          },
        ],
        storeAs: "gradeAssets",
      },
    ];
  });

  const gradeAssets = useSelector(({ firestore: { data } }: RootState) => {
    return data?.gradeAssets || {};
  });
  return useMemo(
    () =>
      Object.entries(gradeAssets).map(([id, asset]: any) => {
        return { gradeId, id, ...asset };
      }),
    [gradeAssets]
  );
};
