import React from 'react';

import { IconButton, LinearProgress } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { storage } from '../firebase';

export default function UploadDocument({ id, test_id, status }: any) {
  console.log(`upload: ${id}`);
  const enable = status !== 'sent';

  function handleChange(event: any, id: any, test_id: string) {
    const storageRef = ref(storage, `StudentTests/${id}/${test_id}/result.png`);
    const uploadTask = uploadBytesResumable(storageRef, event.target!.files[0]);
    uploadTask.on(
      'state_changed',
      () => {
        // const percent = Math.round(
        //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        // ); // update progress
        // setPercent(percent);
      },
      (err) => console.log(err),
      () => {
        // download url

        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          // setTestStatusReview(id, test_id, url); //TODO: fix this
          console.log(url);
        });
      },
    );
  }

  if (enable) {
    return <LinearProgress variant="determinate" value={100} />;
  }
  return (
    <div>
      <input
        type="file"
        onChange={(event) => handleChange(event, id, test_id)}
        accept="/image/*"
        style={{ display: 'none' }}
        id="upload-test-button-file"
      />
      <label htmlFor="upload-test-button-file">
        <IconButton component="span" disabled={enable}>
          <FileUploadIcon></FileUploadIcon>
        </IconButton>
      </label>
    </div>
  );
}
