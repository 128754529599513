import AddDocumentDialog from '../../../dialogs/addDocument';
import AddExerciseDialog from '../../../dialogs/addExercise';
import AddVideoDialog from '../../../dialogs/addVideo';

import './topicContent.css';
import BackButton from '../../../components/buttons/back/BackButton';
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import TopicExerciseList from '../../../components/exerciseList';
import { useTopic } from '../../../hooks/use-topic';
import { useSubTopic } from '../../../hooks/use-subTopic';

function TopicContent() {
  const { grade, topic, id } = useParams();

  const linkUrl = `/exercise/new/${grade}/${topic}/${id}`;
  const topic_doc = useTopic(grade as string, topic as string);
  const subtopic_doc = useSubTopic(grade as string, id as string);

  return (
    <div className="subject">
      <div className="subject-actions">
        <h1>{topic_doc?.name}</h1>
        <h2>{subtopic_doc?.name}</h2>
        <table>
          <tr>
            <td>
              <AddVideoDialog></AddVideoDialog>
            </td>
            <td>
              <AddDocumentDialog></AddDocumentDialog>
            </td>
            <td>
              <AddExerciseDialog></AddExerciseDialog>
            </td>
            <td>
              <Button
                component={Link}
                to={linkUrl}
                variant="contained"
                color="primary"
              >
                תרגיל חדש
              </Button>
            </td>
          </tr>
        </table>
        <TopicExerciseList sub_topic={id}></TopicExerciseList>
      </div>

      <div className="topics_nav">
        <BackButton />
      </div>
    </div>
  );
}

export default TopicContent;
