import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { RootState } from "../store";

export function useNotifications() {
  const user = useSelector((state: any) => state.firebase.auth);

  useFirestoreConnect(() => {
    if (!user?.uid) return [];
    return [
      {
        collection: "users",
        doc: user.uid,
        subcollections: [{ collection: "notifications" }],
        storeAs: "notifications",
        where: ["read" ,"==", false]
      },
    ];
  });

  const notifications = useSelector(
    ({ firestore: { data } }: RootState) => data?.notifications || {}
  );

  return useMemo(
    () =>
      Object.entries(notifications).map(([id, notification]: any) => {
        return { id, ...notification };
      }),
    [notifications]
  );
}
