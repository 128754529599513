import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { signInWithGoogle } from '../../firebase';
import GoogleSvg from '../../assets/icons/google.svg';
import { TextField, Box, Button } from '@mui/material';

function Login() {
  const user = useSelector((state: any) => state.firebase.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (user.uid) {
      navigate('/');
    }
  }, [user.uid, navigate]);

  const handleGoogleSignIn = () => {
    signInWithGoogle();
  };

  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    // Implement your logic to handle username/password login
    // Example: signInWithEmailAndPassword(username, password);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <form onSubmit={handleFormSubmit}>
        <Box
          border="1px solid #ccc"
          borderRadius="8px"
          padding="20px"
          maxWidth="400px"
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box width="100%" my={2}>
            <TextField
              label="Username"
              variant="outlined"
              fullWidth
              required
              // Add necessary props and state for username
            />
          </Box>
          <Box width="100%" my={2}>
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              required
              // Add necessary props and state for password
            />
          </Box>
          <Button variant="contained" color="primary" type="submit" fullWidth>
            Login
          </Button>
          <Box mt={2} border="1px solid #ccc" borderRadius="4px">
            <Button
              fullWidth
              onClick={handleGoogleSignIn}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                alt="Google"
                src={GoogleSvg}
                height={20}
                width={20}
                style={{ marginRight: '8px' }}
              />
              Continue with Google
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
}

export default Login;
