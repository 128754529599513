import React from 'react';
import { IconButton, Paper } from '@mui/material';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { green, orange } from '@mui/material/colors';
import CheckIcon from '@mui/icons-material/Check';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import { useStudentTests } from '../hooks/use-studentTests';

import UploadDocument from './uploadTestResultDocument';

import moment from 'moment';
import { getStatusString } from '../dbOps/examCollection';

async function HandleDownloadTestFile(id: string, url: string) {
  const xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';
  xhr.onload = () => {
    const blob = xhr.response;
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = id;
    a.dispatchEvent(new MouseEvent('click'));
  };
  xhr.open('GET', url);
  xhr.send();
}
function StudentTests({ id }: any) {
  const tests_data = useStudentTests(id);

  function DownloadTestFile({ id, url }: any) {
    return (
      <IconButton onClick={() => HandleDownloadTestFile(id, url)}>
        <DownloadIcon></DownloadIcon>
      </IconButton>
    );
  }
  function DownloadTeacherReviewFile({ id, url, status }: any) {
    console.log(`url = ${url}; id=${id}`);
    if (url !== undefined && status === 'done') {
      return (
        <IconButton
          component="span"
          onClick={() => HandleDownloadTestFile(id, url)}
        >
          <DownloadIcon></DownloadIcon>
        </IconButton>
      );
    } else {
      return <p>not ready</p>;
    }
  }
  function UploadTestResultsFile({ id, test_id, row }: any) {
    console.log(`upload: ${id}`);
    return (
      <UploadDocument
        id={id}
        test_id={test_id}
        status={row.status}
      ></UploadDocument>
    );
  }
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right">תאריך</TableCell>
            <TableCell align="right">סטטוס</TableCell>
            <TableCell align="right">הורדה</TableCell>
            <TableCell align="right">העלאת פתרון</TableCell>
            <TableCell align="right">משוב</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tests_data.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>
                {row.status === 'done' ? (
                  <CheckIcon sx={{ color: green[500] }} />
                ) : (
                  <HourglassBottomIcon sx={{ color: orange[500] }} />
                )}
              </TableCell>
              <TableCell align="right">
                {row.date
                  ? moment(row.date.toDate()).fromNow()
                  : moment(row.createdAt.toDate()).fromNow()}
              </TableCell>
              <TableCell align="right">{getStatusString(row.status)}</TableCell>
              <TableCell align="center">
                <DownloadTestFile id={row.id} url={row.exam_file} />
              </TableCell>
              <TableCell align="center">
                <UploadTestResultsFile id={id} test_id={row.id} row={row} />
              </TableCell>
              <TableCell align="center">
                <DownloadTeacherReviewFile
                  id={id as string}
                  url={row.review_file as string}
                  status={row.status}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default StudentTests;
