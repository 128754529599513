import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { RootState } from "../store";

export const useGradeTopics = (gradeId: string) => {
  const user = useSelector((state: any) => state.firebase.auth);

  useFirestoreConnect(() => {
    if (!user?.uid) return [];
    if (gradeId === "") return [];

    return [
      {
        collection: "topics",
        doc: gradeId,
        subcollections: [
          {
            collection: "sub_topics",
            where: ["grade", "==", gradeId],
          },
        ],
        storeAs: "gradeTopics",
      },
    ];
  });

  const subTopics = useSelector(({ firestore: { data } }: RootState) => {
    return data?.gradeTopics || {};
  });
  return useMemo(
    () =>
      Object.entries(subTopics).map(([id, topic]: any) => {
        return { id, ...topic };
      }),
    [subTopics]
  );
};
