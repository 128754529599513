import React, { useState } from 'react';
import { Grid, Typography, Button, Avatar, TextField, Box } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import MuiPhoneNumber from 'material-ui-phone-number';
import { useSelector } from 'react-redux';
import { GradeSelect } from '../../gradeSelect';
import { useStudent } from '../../../hooks/use-student';
import DeleteStudent from '../../../dialogs/deleteStudent';
import ChildSvg from '../../../assets/icons/avatars/child.svg';
import { UpdateStudent } from '../../../dbOps/usersCollection';
import { useTranslation } from 'react-i18next';

export default function ChildInfo({ id }: any) {
	const child = useStudent(id);
	const user = useSelector((state: any) => state.firebase.auth);
	const [ grade, setGrade ] = useState(child.grade);
	const [ name, setName ] = useState(child.name);
	const [ nameError, setNameError ] = useState(name.length === 0);
	const [ phone, setPhone ] = useState(child.phone);
	const { t } = useTranslation();

	const handleChange = (event: SelectChangeEvent) => {
		setGrade(event.target.value);
	};

	const handlePhoneChange = (value: any) => {
		setPhone(value);
	};

	const handleNameChange = (value: string) => {
		setName(value);
		setNameError(value.length === 0);
	};

	const handleSaveChild = () => {
		UpdateStudent(user.uid, name, phone, grade, id);
	};

	return (
		<div style={{ width: '400px' }}>
			<Grid container spacing={3}>
				<Grid item xs={6}>
					<Typography variant="h6">{t('Name')}</Typography>
					<TextField
						required
						error={nameError}
						variant="outlined"
						helperText={name == '' ? t('Insert Name') : ''}
						value={name}
						onChange={(event) => handleNameChange(event.target.value)}
					/>
				</Grid>
				<Grid item xs={6}>
					<Typography variant="h6">{t('Grade')}</Typography>
					<GradeSelect handleOnChange={handleChange} value={grade} />
				</Grid>
				<Grid item xs={8}>
					<Typography variant="h6">{t('Phone')}</Typography>
					<MuiPhoneNumber
						dir="ltr"
						fullWidth={true}
						defaultCountry={'il'}
						onChange={handlePhoneChange}
						onlyCountries={[ 'il' ]}
						value={phone}
					/>
				</Grid>
				<Grid item xs={8}>
					<Avatar
						alt={child.name}
						sx={{ width: 150, height: 150 }}
						src={child.avatar ? child.avatar : ChildSvg}
					/>
				</Grid>
				<Grid item xs={8}>
					<Box display="flex" justifyContent="center">
						<Button variant="contained" onClick={handleSaveChild} sx={{ ml: 5 }}>
							{t('Save')}
						</Button>
						<DeleteStudent name={name} id={id} />
					</Box>
				</Grid>
			</Grid>
		</div>
	);
}
