import firebase from "firebase/compat/app";
import { db } from "../../firebase";

const AddComment = (id: string, user: any, comment: string) => {
  console.log(comment);
  db.collection("students").doc(id!).collection("comments").add({
    text: comment,
    creator_uid: user.uid,
    creator: user.displayName,
    avatar: user.photoURL,
    created: firebase.firestore.FieldValue.serverTimestamp(),
  });
};
const DeleteComment = (studentId: string, docId: string) => {
  console.log("delete ", studentId);
  db.collection("students")
    .doc(studentId!)
    .collection("comments")
    .doc(docId)
    .delete();
};

export { AddComment, DeleteComment };
