import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import AddIcon from '@mui/icons-material/Add';

export default function AddVideoDialog() {
  const [videoUrl, setVideoUrl] = useState('null');
  const [newVideo, setNewVideo] = useState('');
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  function onUpload(e: any) {
    setVideoUrl(URL.createObjectURL(e.target.files[0]));
  }

  return (
    <div>
      <Button
        variant="contained"
        onClick={() => {
          handleClickOpen();
        }}
      >
        סרטון
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Subscribe</DialogTitle>
        <DialogContent>
          <TextField
            id="standard-basic"
            label="נושא חדש"
            variant="standard"
            style={{ width: 300 }}
            value={newVideo}
            onChange={(e) => {
              setNewVideo(e.target.value);
            }}
          />
          <IconButton component="label">
            <FileUploadIcon></FileUploadIcon>
            <input type="file" onChange={onUpload} hidden />
          </IconButton>
          {videoUrl}
          <IconButton onClick={() => {}}>
            <AddIcon></AddIcon>
          </IconButton>
        </DialogContent>
      </Dialog>
    </div>
  );
}
