import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, Grid, TextField } from '@mui/material';
import { useConfig } from '../../../hooks/use-config';
import { Config, Section, Generator, saveConfig } from '../../../dbOps/configs';
import { useTranslation } from 'react-i18next';

export default function Configs() {
  const [config, setConfig] = useState<Config>({
    manual: {
      generator: {
        advanced: 0,
        beginner: 0,
        completed: 0,
        intermediate: 0,
        rehersal: 0,
      },
      scheduler: '',
    },
    test: {
      generator: {
        advanced: 0,
        beginner: 0,
        completed: 0,
        intermediate: 0,
        rehersal: 0,
      },
      scheduler: '',
    },
    training: {
      generator: {
        advanced: 0,
        beginner: 0,
        completed: 0,
        intermediate: 0,
        rehersal: 0,
      },
      scheduler: '',
    },
  });
  const curConfig = useConfig();
  const { t } = useTranslation();

  useEffect(() => {
    setConfig(curConfig);
  }, [curConfig]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key1: keyof Config,
    key2: keyof Section,
    key3: keyof Generator,
  ) => {
    const value = parseInt(event.target.value);
    setConfig((prevConfig) => {
      const newConfig: Config = { ...prevConfig };
      const updatedGenerator = { ...newConfig[key1].generator, [key3]: value };
      const updatedSection = {
        ...newConfig[key1],
        generator: updatedGenerator,
      };
      newConfig[key1] = updatedSection;
      return newConfig;
    });
  };

  const handleSave = () => {
    saveConfig(config);
    console.log(config);
  };

  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
    >
      <Grid container spacing={3}>
        {Object.entries(config).map(([key1, value1]) => (
          <Grid item xs={4} key={key1}>
            <Card variant="outlined" sx={{ maxWidth: 400 }}>
              <CardContent>
                <h2>{t(key1)}</h2>
                {Object.entries(value1).map(([key2, value2]) => (
                  <Grid container spacing={2} key={`${key1}-${key2}`}>
                    {typeof value2 === 'string' ? (
                      <Grid item xs={12} style={{ marginTop: 20 }}>
                        <TextField label={t(key2)} value={value2} fullWidth />
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        {Object.entries(value2).map(([key3, value3]) => (
                          <Grid
                            item
                            style={{ marginTop: 20 }}
                            key={`${key1}-${key2}-${key3}`}
                          >
                            <TextField
                              label={t(key3)}
                              value={value3}
                              type="number"
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                              ) =>
                                handleChange(
                                  event,
                                  key1 as keyof Config,
                                  key2 as keyof Section,
                                  key3 as keyof Generator,
                                )
                              }
                              fullWidth
                            />
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  </Grid>
                ))}
              </CardContent>
            </Card>
          </Grid>
        ))}
        <Grid item xs={12} style={{ paddingBottom: 20 }}>
          <Button variant="contained" color="primary" onClick={handleSave}>
            {t('Save')}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
