import React from 'react';
import './LandingPage.css'; // Import your CSS file for styling
import { Button } from '@mui/material';
import { useNavigate } from 'react-router';

export default function LandingPage() {
  const navigate = useNavigate();
  return (
    <div className="landing-container">
      <header>
        <h1>ברוכים הבאים</h1>
      </header>
      <main>
        <p>
          ברוכים הבאים לאתר התרגול מתמטיקה! אנו מציעים מגוון רחב של תרגילים,
          תרגולים ופתרונות למתמטיקה, מותאם במיוחד ללמידה אישית.
        </p>
        <p>
          האתר שלנו מיועד לתלמידים המעוניינים בלמידה אישית ובלי תלות בקבוצה.
          התוכן שלנו מיועד לכל הרמות - מתחילים ועד לתלמידי תיכון ומספק תרגול
          שבועי על מנת לאפשר לתמידים לשמר את הידע הקיים ולהמשיך ללמוד תכנים
          חדשים בהתאם לקצב הלמידה האישי של התלמיד ובמעקב באמצעות מבחנים חודשיים
          שיבדקו ע״י מורה ויעדכנו את מצב התלמיד על מנת הנפקת דפי תרגול אפקטיבים.
        </p>
        <p>
          אנו מאמינים בלמידה יעילה, ולכן אנו מספקים תוכן ברור ומסודר עם גרפיקה
          נקייה ופשוטה.
        </p>
        <p>
          כדי להתחיל לתרגל ולהתקדם במתמטיקה בצורה אישית, כנסו לאתר שלנו והתחילו
          לגלות את העולם המרתק של המתמטיקה!
        </p>
      </main>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => navigate('/login')}
      >
        התחל
      </Button>
    </div>
  );
}
