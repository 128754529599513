import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router';
import { db } from '../firebase';
import { useSelector } from 'react-redux';

type DeleteStudentProps = {
  id: string;
  name: string;
};

export default function DeleteStudent({ id, name }: DeleteStudentProps) {
  const [open, setOpen] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [deleteName, setDeleteName] = React.useState('');
  const [nameError, setNameError] = React.useState(true);
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.firebase.auth);

  const handleNameChange = (value: string) => {
    setDeleteName(value);
    setNameError(value !== name);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    if (!nameError) {
      db.collection('users')
        .doc(user.uid)
        .collection('children')
        .doc(id)
        .delete();
      navigate('/');
    }
  };

  return (
    <div>
      <Button variant="contained" autoFocus onClick={handleClickOpen}>
        מחק
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Subscribe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p>על מנת למחוק אנא הקלד את השם ולחץ מחק</p>
            <p>{name}</p>
          </DialogContentText>
          <TextField
            autoFocus
            error={nameError}
            margin="dense"
            id="name"
            label="name"
            type="text"
            fullWidth
            variant="standard"
            onChange={(event) => {
              handleNameChange(event.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>בטל</Button>
          <Button onClick={handleDelete}>מחק</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
