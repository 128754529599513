import * as React from 'react';

import { Button } from '@mui/material';
import { useFamily } from '../../../hooks/use-family';
import { useParams } from 'react-router';

import { useTranslation } from 'react-i18next';
// import { setNewExam, updateExamFileUrl } from '../../../dbOps/examCollection';
// import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Comments from '../../../components/comments/comments';
import StudentProgress from '../../../components/student/studentProgress';
import './teacherView.css';

async function generateExam(student: any) {
  const RequestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      uid: student.parentId,
      studentId: student.id,
    }),
  };
  const response = await fetch('/e/v1/getExam', RequestOptions);
  console.log('response=', response);

  // console.log("student=", student);
  // try {
  //   const req: any = {
  //     studentId: student.id,
  //     uid: student.parentId,
  //   };
  //   const requestOptions = {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify(req),
  //   };

  //   console.log("req=", requestOptions);
  //   const response = await fetch("/getExamExercises", requestOptions);
  //   if (!response.ok) {
  //     throw new Error(
  //       `Failed to fetch exam exercises. Status: ${response.status}`
  //     );
  //   }
  //   console.log("response=", response);

  //   const data = await response.json();
  //   console.log("fetch", data);

  //   const examId = await setNewExam(student.id, "sent", student.parentId, data);

  //   const pdfRequestOptions = {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify({
  //       type: "exam",
  //       return_url: `https://grade-boost.com/exam/uploads/${student.id}/${student.grade}/${examId}`,
  //       exercises: data,
  //     }),
  //   };
  //   const pdfResponse = await fetch("/exercise-pdf", pdfRequestOptions);

  //   if (!pdfResponse.ok) {
  //     throw new Error(`Failed to create PDF. Status: ${pdfResponse.status}`);
  //   }

  //   const blobData = await pdfResponse.blob();

  //   const storage = getStorage();
  //   const storageRef = ref(
  //     storage,
  //     `StudentExams/${student.id}/${student.grade}/${examId}/exam.pdf`
  //   );
  //   const snapshot = await uploadBytes(storageRef, blobData);
  //   console.log("Uploaded a blob or file!", snapshot);
  //   const downloadURL = await getDownloadURL(
  //     ref(storage, snapshot.ref.fullPath)
  //   );

  //   updateExamFileUrl(student.id, examId, downloadURL);

  //   // Update firestore with exam
  //   // UpdateFirestoreFunction(examId, ...);
  // } catch (err) {
  //   console.error("Error:", err);
  //   // Handle the error as needed
  // }
}

export default function TeacherView() {
  const family = useFamily();
  const { id } = useParams();
  const { t } = useTranslation();

  const student = family.filter((child: any) => child.id === id)[0];

  return (
    <div className="teacher-view-container">
      <div className="teacher-view-progress">
        <Button
          autoFocus
          onClick={async () => {
            await generateExam(student);
          }}
        >
          {t('Generate Exam')}{' '}
        </Button>
        {student && <StudentProgress student={student} />}
      </div>
      <div className="teacher-view-comments">
        <Comments id={student?.id} />
      </div>
    </div>
  );
}
