import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { RootState } from "../store";
import { useMemo } from "react";
export const useComments = (studentId: string) => {
  const user = useSelector((state: any) => state.firebase.auth);

  useFirestoreConnect(() => {
    if (!user?.uid) return [];
    if (studentId === "" || studentId === undefined) return [];
    console.log("studentId", studentId);

    return [
      {
        collection: "students",
        doc: studentId,
        subcollections: [{ collection: "comments" }],
        storeAs: "studentComments",
        orderBy: ["created", "desc"],
      },
    ];
  });

  const comments = useSelector(({ firestore: { data } }: RootState) => {
    return data?.studentComments || {};
  });
  return useMemo(
    () =>
      Object.entries(comments).map(([id, comment]: any) => {
        return { id, ...comment };
      }),
    [comments]
  );
};
