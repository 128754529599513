import { InputLabel, MenuItem, Select } from "@mui/material";
import { useTopics } from "../hooks/use-topics";

function TopicSelect({ grade, topic, handleOnChange }: any) {
  const topics = useTopics(grade);
  return (
    <div>
      <InputLabel id="topic-select-label">נושא</InputLabel>
      <Select
        labelId="topic-select-label"
        id="topic-select"
        value={topic}
        onChange={handleOnChange}
        label="Topic"
      >
        {topics.map((t: any, i: any) => (
          <MenuItem value={t.id} key={i}>
            {t.name}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

export default TopicSelect;
