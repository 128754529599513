import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { RootState } from "../store";
import { useMemo } from "react";
export const useTopics = (gradeId: string) => {
  const user = useSelector((state: any) => state.firebase.auth);

  useFirestoreConnect(() => {
    const query = {
      collection: "topics",
      doc: gradeId,
      subcollections: [{ collection: "topics" }],
      storeAs: "myTopics",
    };
    if (!user?.uid) return [];
    if (gradeId === "") return [];
    return [query];
  });

  const topics = useSelector(({ firestore: { data } }: RootState) => {
    return data?.myTopics || {};
  });
  // return Object.entries(topics).map(([id, topic]: any) => {
  //   return { id, ...topic };
  // });
  return useMemo(
    () =>
      Object.entries(topics).map(([id, topic]: any) => {
        return { id, ...topic };
      }),
    [topics]
  );
};
