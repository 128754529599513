import { Button, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { db } from "../../firebase";
import { useCurrentUser } from "../../hooks/use-currentUser";
import "./consentScreen.css";

export default function ConsentScreen() {
  const [agree, setAgree] = useState(false);
  const currentUser = useCurrentUser();
  const user = useSelector((state: any) => state.firebase.auth);
  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgree(event.target.checked);
  };
  const saveconsentStatuse = () => {
    const userRef = db.collection("users").doc(user.uid);
    userRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          // Update existing document
          userRef
            .update({
              consent: agree,
            })
            .then(() => {
              console.log("Consent status updated successfully!");
            })
            .catch((error) => {
              console.error("Error updating consent status:", error);
            });
        } else {
          // Create new document
          userRef
            .set({
              consent: agree,
            })
            .then(() => {
              console.log("New document created successfully!");
            })
            .catch((error) => {
              console.error("Error creating document:", error);
            });
        }
      })
      .catch((error) => {
        console.error("Error getting document:", error);
      });
  };

  useEffect(() => {
    console.log("user = ", user);

    if (currentUser?.consent) {
      navigate("/");
    }
  }, [currentUser]);

  return (
    <div className="consent-screen">
      <p>
        ברוכים הבאים לאתר ללימוד מתמטיקה! אנא קראו את הסכם השימוש הבא, המגדיר את
        התנאים לגבי השימוש באתר זה:
      </p>
      <ol>
        <li>
          השימוש באתר זה הוא למטרות לימוד בלבד. לא ניתן להשתמש באתר למטרות כלשהן
          שאינן קשורות ללימוד מתמטיקה.
        </li>
        <li>
          אתם מאשרים כי השימוש באתר זה נעשה על אחריותכם הבלעדית. הנהלת האתר,
          הסופרים, והמפרסמים אינם אחראים לכל נזק, הפסד, או נזק עקב השימוש באתר.
        </li>

        <li>
          תכני האתר, כולל תכני השיעורים והתרגילים, נועדו למטרות לימוד בלבד ואינם
          מיועדים לשימוש מסחרי או לפרסום במקום אחר ללא אישור כתב מראש.
        </li>
        <li>
          אתם מסכימים שהנהלת האתר יכולה להפסיק או להשעות את הגישה לאתר בכל עת
          לפי שיקול דעתה הבלעדי.
        </li>
        <li>
          אנו שומרים לעצמנו את הזכות לעדכן את התנאים של הסכם השימוש מעת לעת,
          והשימוש המתמשך באתר מהווה את הסכמתכם לכל השינויים שייעשו.
        </li>
        <li>
          עליכם לשמור על הגישה לאתר ולא לחשוף את הסיסמאות או פרטי הכניסה לאתר
          לצדדים שלישיים.
        </li>
      </ol>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={agree} onChange={handleChange} />}
          label="קראתי ואני מסכים"
        />
        <Button
          variant="contained"
          disabled={!agree}
          onClick={saveconsentStatuse}
        >
          שמור
        </Button>
      </FormGroup>
    </div>
  );
}
