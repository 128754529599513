import React, { useEffect } from 'react';
import { Button } from '@material-ui/core';
import { useParams } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { useGradeAssets } from '../../../hooks/use-gradeAssets';

import GradeAssetsUploader from '../../../components/assets/gradeAssetsUploader';
import GradeAssetsView from '../../../components/assets/gradeAssetsView';
import MissingAssetIndicator from '../../../components/assets/missingAssetIndicator';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  status: {
    display: 'flex',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    gap: '20px',
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '20px',
  },
}));

const Grade: React.FC = () => {
  const { grade } = useParams<{ grade: string }>();
  const [hasFirstExam, setHasFirstExam] = React.useState(false);
  const [hasMidExam, setHasMidExam] = React.useState(false);
  const [hasEndExam, setHasEndExam] = React.useState(false);

  const classes = useStyles();
  const gradeAssets = useGradeAssets(grade || '');
  console.log(gradeAssets);

  useEffect(() => {
    if (gradeAssets) {
      setHasFirstExam(gradeAssets.some((asset) => asset.type === 'exam_1'));
      setHasMidExam(gradeAssets.some((asset) => asset.type === 'exam_2'));
      setHasEndExam(gradeAssets.some((asset) => asset.type === 'exam_3'));
    }
  }, [gradeAssets]);

  return (
    <div className={classes.root}>
      <div className={classes.status}>
        <MissingAssetIndicator label="מבחן ראשון" exist={hasFirstExam} />
        <MissingAssetIndicator label="מבחן אמצע" exist={hasMidExam} />
        <MissingAssetIndicator label="מבחן סוף" exist={hasEndExam} />
      </div>
      <div className={classes.button}>
        <Button variant="contained" color="primary" href="/backoffice/grades">
          חזור לרשימת כיתות
        </Button>
        <Button
          variant="contained"
          color="primary"
          href={`/backoffice/grades/${grade || ''}/topics`}
        >
          נושאים
        </Button>
        <GradeAssetsUploader grade={grade ?? ''} />
      </div>
      <GradeAssetsView grade={grade ?? ''} />
    </div>
  );
};

export default Grade;
