type GradeAssetData = {
  id: string;
  type: string;
  description: string;
  link: string;
  labels: string[];
  url: string;
};

enum AssetType {
  EXAM_1 = 'exam_1',
  EXAM_2 = 'exam_2',
  EXAM_3 = 'exam_3',
  LINK = 'link',
  DOCUMENT = 'document',
  VIDEO = 'video',
}
export { AssetType };
export type { GradeAssetData };
