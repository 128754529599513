import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import AddIcon from '@mui/icons-material/Add';

export default function AddExerciseDialog() {
  const [exerciseImage, setExerciseImage] = useState('null');
  const [newExercise, setNewExercise] = useState('');
  const [open, setOpen] = useState(false);

  function onUpload(e: any) {
    setExerciseImage(URL.createObjectURL(e.target.files[0]));
  }
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <Button
        variant="contained"
        onClick={() => {
          handleClickOpen();
        }}
      >
        דף תרגילים
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Subscribe</DialogTitle>
        <DialogContent>
          <TextField
            id="standard-basic"
            label="נושא חדש"
            variant="standard"
            style={{ width: 300 }}
            value={newExercise}
            onChange={(e) => {
              setNewExercise(e.target.value);
            }}
          />
          <img
            src={exerciseImage}
            width={100}
            height={100}
            loading="lazy"
            alt="exercise"
          />
          <IconButton component="label">
            <FileUploadIcon></FileUploadIcon>
            <input type="file" onChange={onUpload} hidden />
          </IconButton>
          <IconButton onClick={() => {}}>
            <AddIcon></AddIcon>
          </IconButton>
        </DialogContent>
      </Dialog>
    </div>
  );
}
