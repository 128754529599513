import React, { useEffect, useState } from 'react';
import { GetGradeByID, GradeSelect } from './gradeSelect';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { Avatar, TextField, Button } from '@mui/material';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import firebase from 'firebase/compat/app';
import { AddNewStudent } from '../dbOps/usersCollection';

enum Steps {
  NAME = 1,
  EMAIL,
  GRADE,
  PHONE,
  AVATAR,
  SUBMIT,
}

type AddStudentFlowData = {
  name: string;
  email: string;
  phone: string;
  grade: string;
  avatar: string;
};
type AddStudentFlowProps = {
  onSubmit: (formData: AddStudentFlowData) => void;
};

function NavArrowIcon(forward: boolean) {
  if (forward) {
    if (document.documentElement.dir === 'rtl') {
      return <ArrowForward />;
    } else {
      return <ArrowBack />;
    }
  } else {
    if (document.documentElement.dir === 'rtl') {
      return <ArrowBack />;
    } else {
      return <ArrowForward />;
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function AddStudentFlow({ onSubmit }: AddStudentFlowProps) {
  const [step, setStep] = useState<number>(1);
  const user = useSelector((state: any) => state.firebase.auth);
  const [avatars, setAvatars] = useState<string[]>([]);
  const [selectedAvatarIndex, setSelectedAvatarIndex] = useState<number | null>(
    null,
  );

  const defaultAvatar =
    'https://firebasestorage.googleapis.com/v0/b/math-practice-2350d.appspot.com/o/studentAvatar%2Fchild.svg?alt=media&token=b83ec1d1-d8c5-4b02-be08-b1d466af3138';

  const navigate = useNavigate();

  useEffect(() => {
    const storageRef = firebase.storage().ref();

    // List all files in a particular folder
    const folderRef = storageRef.child('studentAvatar');
    folderRef
      .listAll()
      .then(function (res) {
        const arr: string[] = [];
        res.items.forEach(function (itemRef) {
          // Get the URL with access token
          itemRef.getDownloadURL().then(function (url) {
            // Use the URL here
            arr.push(url);
            console.log(url);
          });
        });
        console.log(`arr = ${arr}`);
        setAvatars(arr);
      })
      .catch(function (error) {
        // Handle any errors here
        console.log(error);
      });
  }, []);

  const [formData, setFormData] = useState<AddStudentFlowData>({
    name: '',
    email: '',
    phone: '',
    grade: 'grade-1',
    avatar: defaultAvatar,
  });

  function handleNext(event: React.FormEvent<HTMLButtonElement>) {
    event.preventDefault();
    setStep((prevStep) => prevStep + 1);
  }

  function handlePrev(event: React.FormEvent<HTMLButtonElement>) {
    event.preventDefault();
    setStep((prevStep) => prevStep - 1);
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    console.log(event);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [event.target.name]: event.target.value,
    }));
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    console.log(formData);
    setStep(1);

    AddNewStudent(
      formData.name,
      formData.email,
      formData.phone,
      formData.grade,
      formData.avatar,
      user,
    );

    setFormData({
      name: '',
      email: '',
      phone: '',
      grade: 'grade-1',
      avatar: defaultAvatar,
    });
    navigate('/');
  }

  function NavButtons() {
    if (step === Steps.NAME) {
      return (
        <div>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleNext}
            startIcon={NavArrowIcon(true)}
          >
            הבא
          </Button>
        </div>
      );
    } else if (step === Steps.SUBMIT) {
      return (
        <div style={{ display: 'flex', justifyContent: 'normal' }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handlePrev}
            startIcon={NavArrowIcon(false)}
          >
            קודם
          </Button>
          <Button variant="outlined" color="primary" type="submit">
            שמור
          </Button>
        </div>
      );
    } else {
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handlePrev}
            startIcon={NavArrowIcon(false)}
          >
            קודם
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleNext}
            startIcon={NavArrowIcon(true)}
          >
            הבא
          </Button>
        </div>
      );
    }
  }

  return (
    <form onSubmit={handleSubmit} style={{ width: '600px' }}>
      {step === Steps.NAME && (
        <div>
          <TextField
            style={{ width: '100%' }}
            label="name"
            name="name"
            value={formData.name}
            helperText="Enter name"
            onChange={handleInputChange}
          />
          <NavButtons />
        </div>
      )}
      {step === Steps.EMAIL && (
        <div>
          <TextField
            style={{ width: '100%' }}
            label="email"
            name="email"
            value={formData.email}
            helperText="Enter email"
            onChange={handleInputChange}
          />
          <NavButtons />
        </div>
      )}
      {step === Steps.GRADE && (
        <div>
          <GradeSelect
            style={{ width: '100%' }}
            name="grade"
            handleOnChange={handleInputChange}
            value={formData.grade}
          />
          <NavButtons />
        </div>
      )}
      {step === Steps.PHONE && (
        <div>
          <TextField
            style={{ width: '100%' }}
            label="phone"
            name="phone"
            type="tel"
            value={formData.phone}
            helperText="Enter phone"
            onChange={handleInputChange}
          />

          <NavButtons />
        </div>
      )}
      {step === Steps.AVATAR && (
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap',
              alignItems: 'center',
              maxWidth: '600px',
            }}
          >
            {avatars.map((avatar: string, index) => (
              <Avatar
                key={index}
                alt={`avatar-${index}`}
                sx={{ width: 150, height: 150 }}
                src={avatar}
                onClick={() => {
                  setSelectedAvatarIndex(index);
                  formData.avatar = avatar;
                }}
                style={{
                  border:
                    selectedAvatarIndex === index ? '2px solid blue' : 'none',
                  width: '25%',
                  boxSizing: 'border-box',
                  padding: '10px',
                }}
              />
            ))}
            {selectedAvatarIndex !== null && (
              <p>You have selected avatar {selectedAvatarIndex + 1}</p>
            )}
          </div>
          <div>
            <NavButtons />
          </div>
        </div>
      )}
      {step === Steps.SUBMIT && (
        <div>
          <table style={{ width: '100%' }}>
            <tr>
              <td>שם</td>
              <td>{formData.name}</td>
            </tr>
            <tr>
              <td>מייל</td>
              <td>{formData.email}</td>
            </tr>
            <tr>
              <td>טלפון</td>
              <td>{formData.phone}</td>
            </tr>
            <tr>
              <td>כיתה</td>
              <td>{GetGradeByID(formData.grade)}</td>
            </tr>
            <tr>
              <td />
              <td>
                <Avatar
                  alt={`avatar`}
                  sx={{ width: 150, height: 150 }}
                  src={formData.avatar}
                />
              </td>
            </tr>
          </table>
          <NavButtons />
        </div>
      )}
    </form>
  );
}

export default AddStudentFlow;
