import {
  Button,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Paper,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useNavigate } from "react-router";
import { useWaitingForReview } from "../../hooks/use-waiting-for-review";

export function ExamsWaitingForReview() {
  const examsWaitingForReview = useWaitingForReview();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <TableContainer component={Paper} sx={{ maxWidth: 800, width: "100vw" }}>
        <Table aria-label="exams table">
          <TableHead>
            <TableRow>
              <TableCell align="center">{t("Date")}</TableCell>
              <TableCell align="center">{t("Status")}</TableCell>
              <TableCell align="center">{t("Action")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {examsWaitingForReview.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="center">
                  {row.createdAt
                    ? moment(row.createdAt.toDate()).fromNow()
                    : row.date
                    ? moment(row.date.toDate()).fromNow()
                    : "N/A"}
                </TableCell>
                <TableCell align="center">{row.status}</TableCell>
                <TableCell align="center">
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      navigate(`/exam-check/${row.studentId}/${row.id}`)
                    }
                  >
                    {t("Click")}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
