import { Button, IconButton, TextField } from '@mui/material';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import EditIcon from '@mui/icons-material/Edit';

import React, { useState } from 'react';
import { db } from '../firebase';
import { useNavigate } from 'react-router';

const useStyles = makeStyles(
  createStyles({
    name: { 'font-size': '15px' },
  }),
);

export default function EditableTextField(props: {
  title: string;
  classname: any;
  dbPath: string;
  docId: string;
  clickNavPath: string;
}) {
  const { title, classname, dbPath, docId, clickNavPath } = props;
  const [name, setName] = useState(title);
  const [isNameFocused, setIsNamedFocused] = useState(false);
  const classes = useStyles(classname);
  const navigate = useNavigate();

  function handleEditName(focused: boolean) {
    console.log(dbPath);
    console.log(docId);
    if (!focused) {
      db.collection(dbPath).doc(docId).update({
        name: name,
      });
    }
    setIsNamedFocused(focused);
  }

  function handleNavigate() {
    console.log(`navigate to ${clickNavPath}`);
    if (!isNameFocused) {
      navigate(clickNavPath);
    }
  }

  return (
    <div className="editable-text">
      {!isNameFocused ? (
        <div>
          <Button variant="text" onClick={handleNavigate}>
            {name}
          </Button>
          <IconButton
            aria-label="edit"
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            onClick={(event) => {
              setIsNamedFocused(true);
            }}
          >
            <EditIcon />
          </IconButton>
        </div>
      ) : (
        // TODO: need to save to database only on change
        <div>
          <TextField
            autoFocus
            inputProps={{ className: classes.name }}
            value={name}
            onChange={(event) => setName(event.target.value)}
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            onBlur={(event) => handleEditName(false)}
          />
        </div>
      )}
    </div>
  );
}
