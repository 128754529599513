import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { RootState } from "../store";
export const useTopicExercises = (subTopic: any) => {
  const user = useSelector((state: any) => state.firebase.auth);

  useFirestoreConnect(() => {
    if (!user?.uid) return [];
    return [
      {
        collection: "exercisesPerTopic",
        doc: subTopic,
        storeAs: "topicExercises",
        limit: 10,
        subcollections: [
          {
            collection: subTopic,
          },
        ],
      },
    ];
  });

  const topicExercises = useSelector(({ firestore: { data } }: RootState) => {
    return data?.topicExercises || {};
  });

  return useMemo(
    () =>
      Object.entries(topicExercises).map(([id, exe]: any) => {
        return { id, ...exe };
      }),
    [topicExercises]
  );
};
