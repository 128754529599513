import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { RootState } from "../store";

export function useFamily() {
  const user = useSelector((state: any) => state.firebase.auth);

  useFirestoreConnect(() => {
    if (!user?.uid) return [];
    return [
      {
        collection: "users",
        doc: user.uid,
        subcollections: [{ collection: "children" }],
        storeAs: "family",
      },
    ];
  });

  const family = useSelector(
    ({ firestore: { data } }: RootState) => data?.family || {}
  );

  return useMemo(
    () =>
      Object.entries(family).map(([id, child]: any) => {
        return { id, ...child };
      }),
    [family]
  );
}
