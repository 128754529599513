import './topics.css';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { IconButton, TextField } from '@mui/material';
import { useTopics } from '../../../hooks/use-topics';
import { db } from '../../../firebase';
import { useParams } from 'react-router';
import BackButton from '../../../components/buttons/back/BackButton';
import EditableTextField from '../../../components/editableTextField';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: 60,
  lineHeight: '60px',
  alignContent: 'center',
}));

const TopicList = ({ grade }: any) => {
  const topics = useTopics(grade);

  return topics ? (
    <Grid
      className="topics_container"
      container
      spacing={{ xs: 2, md: 4 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
    >
      {topics.map((topic: any) => (
        <Grid item xs={2} sm={4} md={4} key={topic.id}>
          <Item key={topic.id} elevation={16}>
            <EditableTextField
              title={topic.name}
              classname=""
              dbPath={`topics/${grade}/topics`}
              docId={topic.id}
              clickNavPath={`/backoffice/grades/${grade}/topic/${topic.id}`}
            ></EditableTextField>
          </Item>
        </Grid>
      ))}
    </Grid>
  ) : (
    <></>
  );
};

const Topics = () => {
  const [newTopic, setNewTopic] = useState('');
  const { grade } = useParams();

  // const navigate = useNavigate();

  const handleTopicAdd = () => {
    db.collection('topics').doc(grade).collection('topics').add({
      name: newTopic,
    });
    setNewTopic('');
  };

  return (
    <div className="topics">
      <div className="topics_add">
        <TextField
          id="standard-basic"
          label="נושא חדש"
          variant="standard"
          style={{ width: 300 }}
          value={newTopic}
          onChange={(e) => {
            setNewTopic(e.target.value);
          }}
        />
        <IconButton
          onClick={() => {
            handleTopicAdd();
          }}
        >
          <AddIcon></AddIcon>
        </IconButton>
      </div>

      <div className="topics_data">
        <TopicList grade={grade}></TopicList>
      </div>
      <div className="topics_nav">
        <BackButton />
      </div>
    </div>
  );
};

export default Topics;
