import React, { useState, useRef, ChangeEvent, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { useTranslation } from 'react-i18next';

import { storage } from '../../firebase';

import './uploads.css';
import { useParams } from 'react-router';
import { setExamUploadFiles } from '../../dbOps/examCollection';
import { useSnackbar } from 'notistack';

const Uploads = () => {
  const { studentId, examId, gradeId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [imageObjects, setImageObjects] = useState<
    { imagePreview: string; imageFile: File }[]
  >([]);
  const handleFileInput = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleClick = () => {
    if (handleFileInput.current && imageObjects.length < 5) {
      handleFileInput.current.click();
    }
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const newImage = {
        imagePreview: URL.createObjectURL(event.target.files[0]),
        imageFile: event.target.files[0],
      };
      setImageObjects([...imageObjects, newImage]);
    }
  };

  const handleRemoveImage = (index: number) => {
    const updatedImages = [...imageObjects];
    updatedImages.splice(index, 1);
    setImageObjects(updatedImages);
  };

  const uploadImagesToFirebaseStorage = async (images: File[]) => {
    const storageRef = storage.ref();
    const timestamp = Date.now();
    const downloadUrls: string[] = [];
    const imagePromises = images.map(async (image, index) => {
      try {
        // Read the selected image file as a data URL
        const imageDataUrl = await readImageAsDataUrl(image);

        // Resize and compress the image
        const resizedImageDataUrl = await resizeAndCompressImage(imageDataUrl);

        // Convert the data URL back to a Blob
        const resizedImageBlob = dataUrlToBlob(resizedImageDataUrl);

        // Upload the resized image to Firebase Storage
        const imageRef = storageRef.child(
          `StudentExams/${studentId}/${gradeId}/${examId}/studentUploads/image-${timestamp}-${index}.jpg`,
        );

        await imageRef.put(resizedImageBlob);

        const downloadUrl = await imageRef.getDownloadURL();
        downloadUrls.push(downloadUrl);
      } catch (error) {
        console.error(`Error processing image ${index}:`, error);
        throw error; // Rethrow the error to stop processing if one image fails
      }
    });

    try {
      await Promise.all(imagePromises);
      if (downloadUrls.length === images.length) {
        if (studentId && examId) {
          setExamUploadFiles(studentId, examId, downloadUrls);
          enqueueSnackbar(`downloadUrls.length ${downloadUrls.length}`, {
            variant: 'success',
          });
          downloadUrls.forEach((url: string) => {
            enqueueSnackbar(url, { variant: 'success' });
          });
          enqueueSnackbar('נשלח', { variant: 'success' });
        }
      }
      console.log('Images uploaded to Firebase Storage successfully!');
    } catch (error) {
      console.error('Error uploading images to Firebase Storage:', error);
      enqueueSnackbar('issue', { variant: 'error' });
    }
  };

  // Function to read an image as a data URL
  const readImageAsDataUrl = (image: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(image);
    });
  };

  // Function to resize and compress an image data URL
  // Function to resize and compress an image data URL
  const resizeAndCompressImage = async (dataUrl: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => {
        const maxWidth = 1200;
        const maxHeight = 800;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (ctx) {
          let newWidth, newHeight;
          if (image.width > image.height) {
            newWidth = maxWidth;
            newHeight = (image.height / image.width) * maxWidth;
          } else {
            newHeight = maxHeight;
            newWidth = (image.width / image.height) * maxHeight;
          }

          canvas.width = newWidth;
          canvas.height = newHeight;
          ctx.drawImage(image, 0, 0, newWidth, newHeight);

          // Convert the canvas content back to a data URL with reduced size and quality
          const resizedDataUrl = canvas.toDataURL('image/jpeg', 0.8); // Adjust quality as needed
          resolve(resizedDataUrl);
        } else {
          // Handle the case where getContext returns null
          reject(new Error('Canvas context is null'));
        }
      };
      image.src = dataUrl;
    });
  };

  // Function to convert a data URL to Blob
  const dataUrlToBlob = (dataUrl: string): Blob => {
    const parts = dataUrl.split(',');
    const contentType = parts[0].split(':')[1].split(';')[0];
    const data = atob(parts[1]);
    const buffer = new Uint8Array(data.length);
    for (let i = 0; i < data.length; i++) {
      buffer[i] = data.charCodeAt(i);
    }
    return new Blob([buffer], { type: contentType });
  };

  const handleSave = () => {
    const imageFiles = imageObjects.map((imageObject) => imageObject.imageFile);

    // Call the function to upload images to Firebase Storage
    uploadImagesToFirebaseStorage(imageFiles);

    // Clear the imageObjects state (optional)
    setImageObjects([]);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const buttonLabel = isMobile
    ? t('Capture')
    : imageObjects.length > 0
      ? t('Add File')
      : t('Upload');

  const isCaptureDisabled = imageObjects.length >= 5;

  return (
    <div>
      <div className="image-list">
        <div className="card-container">
          {imageObjects.map((image, index) => (
            <Card key={index} className="image-item">
              <CardContent>
                <p className="image-number">{index + 1}</p>
                <img
                  src={image.imagePreview}
                  alt={`Uploaded ${index}`}
                  className="thumbnail"
                />
              </CardContent>
              <CardActions>
                <IconButton
                  className="close-button"
                  onClick={() => handleRemoveImage(index)}
                  color="primary"
                >
                  <CloseIcon />
                </IconButton>
              </CardActions>
            </Card>
          ))}
        </div>
      </div>
      <div className="button-container">
        <button onClick={handleClick} disabled={isCaptureDisabled}>
          {buttonLabel}
        </button>
        {imageObjects.length > 0 && (
          <button onClick={handleSave}>{t('Save')}</button>
        )}
      </div>
      <label>
        <input
          style={{ display: 'none' }}
          type="file"
          accept="image/*"
          capture="environment"
          ref={handleFileInput}
          onChange={handleImageChange}
        />
      </label>
    </div>
  );
};

export default Uploads;
