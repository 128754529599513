import { getDatabase, ref ,update} from "firebase/database";

type Generator = {
	advanced: number;
	beginner: number;
	completed: number;
	intermediate: number;
	rehersal: number;
};

type Section = {
	generator: Generator;
	scheduler: string;
};

type Config = {
	manual: Section;
	test: Section;
	training: Section;
};

function saveConfig(config: Config) {
    const db = getDatabase();
    const updates: Partial<{ config: Config }> = {}; // Use Partial to indicate that not all properties are required
    updates['config'] = config;
    update(ref(db), updates as any); // Use "as any" to temporarily bypass the type checking if necessary
}


export type { Config, Generator, Section };
export { saveConfig };