import StudentSelection from '../../components/StudentSelection';
import { useParams } from 'react-router';

import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import StudentTests from '../../components/StudentTests';
import ChildInfo from '../../components/child/settings/childInfo';
import { useTranslation } from 'react-i18next';

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function Student() {
  const [value, setValue] = React.useState(0);
  const { id } = useParams();
  const { t } = useTranslation();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <Box
        sx={{
          display: 'flex',

          flexDirection: 'column',
          minWidth: '240px',
          position: 'fixed',
          top: '120px',
          right: 0,
          bottom: 0,
          zIndex: 1000, // Ensure it's above other content
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{
            borderRight: 1,
            borderColor: 'divider',
          }}
        >
          <Tab label={t('progress')} {...a11yProps(0)} />
          <Tab label={t('exams')} {...a11yProps(1)} />
          <Tab label={t('settings')} {...a11yProps(2)} />
        </Tabs>
      </Box>
      <Box
        sx={{
          marginLeft: '240px', // Adjust according to the width of the fixed tab menu
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <TabPanel value={value} index={0}>
          <StudentSelection id={id}></StudentSelection>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <StudentTests id={id}></StudentTests>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ChildInfo id={id}></ChildInfo>
        </TabPanel>
      </Box>
    </Box>
  );
}
