import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';

// Define a type for the slice state
type StudentState = {
  currentStudent: object;
};

// Define the initial state using that type
const initialState: StudentState = {
  currentStudent: {},
};

export const studentSlice: Slice<StudentState> = createSlice({
  name: 'currentStudent',
  initialState,
  reducers: {
    setStudent: (state, action: PayloadAction<object>) => {
      state.currentStudent = action.payload;
    },
  },
});

export const { setStudent } = studentSlice.actions;

export default studentSlice.reducer;
