import './exam.css';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Stage, Layer, Line, Image } from 'react-konva';
import { useImage } from '../../hooks/use-image';

import Konva from 'konva';
import firebase from 'firebase/compat/app';
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';

import { Button, Drawer } from '@mui/material';

import {
  setTestStatusDone,
  setTestStatusReview,
} from '../../dbOps/examCollection';
import { useStudentExam } from '../../hooks/use-exam';
import { useStudent } from '../../hooks/use-student';
import Comments from '../../components/comments/comments';
import StudentProgress from '../../components/student/studentProgress';
import ToolBar from '../../components/editor/toolbox/toolbar';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';

function ExamCheck() {
  const { sid, examId, gradeId } = useParams();
  const [lines, setLines] = useState<any>([]);
  const [history, setHistory] = useState<any>([]);
  const [test_image, setTestImage] = useState('');
  const [image_version, setImageVersion] = useState<number>(0);
  const [image] = useImage(test_image, 'anonymous', undefined, image_version);
  const [tool, setTool] = useState('brush');
  const [brushSize, setBurshSize] = useState('4');
  const [brushColor, setBrushColor] = useState('black');
  const navigate = useNavigate();
  const isDrawing = useRef(false);
  const [examPageIndex, setExamPageIndex] = useState<number>(0);
  const [showStudentProgress, setShowStudentProgress] = useState(false);
  const [showStudentComments, setShowStudentComments] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const student = useStudent(sid as string);

  const stageRef: React.RefObject<Konva.Stage> = React.useRef(null);

  const exam = useStudentExam(sid as string, examId as string);

  useEffect(() => {
    // console.log(
    //   `examPageIndex = ${examPageIndex}, exam.checks = ${exam?.checks}`
    // );
    if (exam !== undefined) {
      if (
        exam.checks !== undefined &&
        exam.checks[examPageIndex] !== '' &&
        exam.checks[examPageIndex] !== undefined
      ) {
        console.log('check found set check image');
        setTestImage(exam.checks[examPageIndex]);
      } else {
        console.log('no check found set original upload');
        setTestImage(exam.uploads[examPageIndex]);
      }
    }
  }, [exam, examPageIndex]);
  const MemoizedImage = useMemo(() => {
    // console.log(`version = ${image_version}`);
    return <Image image={image} key={image_version} />;
  }, [image, image_version]);

  const handleMouseDown = (e: any) => {
    isDrawing.current = true;
    e.target.getStage().container().style.cursor = 'crosshair';

    const pos = e.target.getStage().getPointerPosition();

    setLines([...lines, { points: [pos.x, pos.y] }]);
  };

  const StudentProgressDrawer = ({ student }: any) => {
    const toggleDrawer =
      (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }
        setShowStudentProgress(open);
      };
    return (
      <React.Fragment key="right-drawer">
        <Button onClick={toggleDrawer(true)}>Student Progress</Button>
        <Drawer
          anchor="right"
          open={showStudentProgress}
          onClose={toggleDrawer(false)}
        >
          <Button onClick={toggleDrawer(false)}>close</Button>
          <Box
            sx={{ width: '100%', direction: 'rtl', padding: '20px' }}
            component="div"
            role="presentation"
            onClick={toggleDrawer(false) as React.MouseEventHandler} // Add event type
            onKeyDown={toggleDrawer(false) as React.KeyboardEventHandler} // Add event type
          >
            {student && (
              <StudentProgress student={student} showDownloadButton={false} />
            )}
          </Box>
        </Drawer>
      </React.Fragment>
    );
  };

  const StudentCommentsDrawer = ({ student }: any) => {
    const toggleDrawer =
      (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }

        setShowStudentComments(open);
      };
    return (
      <React.Fragment key="right-drawer">
        <Button onClick={toggleDrawer(true)}>Student Comments</Button>
        <Drawer anchor="left" open={showStudentComments}>
          <Box
            sx={{ width: 500, direction: 'rtl', padding: '20px' }}
            component="div"
            role="presentation"
            onClick={toggleDrawer(false) as React.MouseEventHandler} // Add event type
            onKeyDown={toggleDrawer(false) as React.KeyboardEventHandler} // Add event type
          >
            <Button onClick={toggleDrawer(false)}>close</Button>
            <Comments id={student?.id} />
          </Box>
        </Drawer>
      </React.Fragment>
    );
  };
  const handleMouseMove = (e: any) => {
    // no drawing - skipping
    if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();

    // To draw line
    const lastLine: any = lines[lines.length - 1];

    if (lastLine) {
      // add point
      lastLine.points = lastLine.points.concat([point.x, point.y]);
      lastLine.tool = tool;
      lastLine.strokeWidth = brushSize;
      lastLine.stroke = brushColor;

      // replace last
      lines.splice(lines.length - 1, 1, lastLine);
      setLines(lines.concat());
    }
  };
  const handleResetImage = () => {
    setTestImage(exam.result_file);
    setHistory([...lines]);
    setLines([]);
    enqueueSnackbar('reset done', { variant: 'success' });
    //get original uploaded file
  };
  const handleUnDo = () => {
    const removedLine = lines.pop();
    if (removedLine) {
      setHistory([...history, removedLine]);
      setLines([...lines]);
    }
  };
  const handleRedo = () => {
    const undoItem = history.pop();
    if (undoItem) {
      setHistory([...history]);
      setLines([...lines, undoItem]);
    }
  };

  //TODO: change status to review only if status is sent
  function handleSave(sid: string, id: string) {
    // console.log(`save: ${sid} ${id} examPageIndex: ${examPageIndex}`);
    if (stageRef.current != null) {
      const uri = stageRef.current.toDataURL();
      const storageRef = firebase.storage().ref();
      const reviewImage = storageRef.child(
        `StudentExams/${sid}/${student.grade}/${id}/checks/check-${examPageIndex}.jpeg`,
      );

      reviewImage
        .putString(uri, 'data_url', {
          contentType: 'image/png',
        })
        .then(() => {
          reviewImage.getDownloadURL().then((url: string) => {
            let checks = [...exam.checks];
            if (checks === undefined) {
              checks = [];
            }
            for (let i = 0; i < examPageIndex; i++) {
              if (checks[i] === undefined) {
                checks.push('');
              }
            }
            checks[examPageIndex] = url;
            // if (checks[examPageIndex] === undefined) {
            //   checks = [...checks, url];
            // }
            // console.log(`checks: ${checks}`);
            setTestStatusReview(sid, id, checks);
            setTestImage(url);
            setImageVersion(image_version + 1);
            enqueueSnackbar('Saved', { variant: 'success' });
          });
        });
    }
  }

  function handleDoneCheck(sid: string, id: string) {
    const body = {
      studentId: sid,
      examId: id,
      gradeId: gradeId,
      imageUrls: exam.checks,
    };
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    };

    fetch('/GenerateReviewPDF', requestOptions).then(() => {
      const storage = getStorage();
      const docRef = ref(
        storage,
        `StudentExams/${sid}/${student.grade}/${id}/review.pdf`,
      );
      getDownloadURL(docRef).then((url: string) => {
        console.log(`url: ${url}`);
        setTestStatusDone(sid, id, url);
        enqueueSnackbar('נשלח', { variant: 'success' });
      }); //TODO: handle error
    });
  }

  const handleMouseUp = (e: any) => {
    isDrawing.current = false;
    e.target.getStage().container().style.cursor = 'default';
  };

  return (
    <div className="container text-center text-dark">
      <div className="progress-area">
        <StudentProgressDrawer student={student} />
      </div>
      <div className="edit-area">
        <div className="action">
          <ToolBar
            onSave={() => {
              handleSave(sid as string, examId as string);
              setLines([]);
            }}
            onNavigateTeacherView={() => {
              navigate(`/teacher-view/${sid}`);
            }}
            onRedo={handleRedo}
            onUndo={handleUnDo}
            onResetImage={handleResetImage}
            onDoneCheck={() => {
              handleDoneCheck(sid as string, examId as string);
            }}
            pageCount={exam !== undefined ? exam.uploads?.length + 1 || 1 : 1}
            onPageChange={(page: number) => {
              setExamPageIndex(page);
            }}
            onBrushColorChange={(color: string) => {
              setBrushColor(color);
            }}
            onToolChanged={(tool: string) => {
              setTool(tool);
            }}
            onBrushSizeChanged={(size: number) => {
              setBurshSize(size.toString());
            }}
            brushSize={brushSize}
          ></ToolBar>
        </div>

        <Stage
          width={1200}
          height={800}
          onMouseDown={handleMouseDown}
          onMousemove={handleMouseMove}
          onMouseup={handleMouseUp}
          className="canvas-stage"
          ref={stageRef}
        >
          <Layer>
            {MemoizedImage}
            {lines.map((line: any, i: number) => (
              <Line
                key={i}
                points={line.points}
                stroke={line.stroke}
                strokeWidth={line.strokeWidth}
                tension={0.5}
                lineCap="round"
                globalCompositeOperation={
                  line.tool === 'eraser' ? 'destination-out' : 'source-over'
                }
              />
            ))}
          </Layer>
        </Stage>
      </div>
      <div className="comments">
        <StudentCommentsDrawer student={student} />
      </div>
    </div>
  );
}

export default ExamCheck;
