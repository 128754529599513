import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import Fab from '@mui/material/Fab';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';

export default function DownloadExeButton({
  row,
  selected_topics,
  disabled,
  studentId,
  userId,
}: any) {
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };
  const handleButtonClick = () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      console.log('row', row);
      console.log('row.subtopic', row.subtopic);
      console.log('selected_topics=', selected_topics);
      // Get the keys (ids) from the selected_topics object
      const selectedTopicIds = Object.keys(selected_topics);
      console.log('selectedTopicIds=', selectedTopicIds);

      // Filter row.subtopic based on the ids from selectedTopicIds
      const stpks = row.subtopic
        .filter((t: any) => selectedTopicIds.includes(t.id))
        .map((t: any) => t.id);

      console.log('stpks=', stpks);

      const req: any = {
        generator: 'manual',
        studentId: studentId,
        uid: userId,
        sub_topics: stpks,
      };
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req),
      };
      // setLoading(true);
      // return new Promise((resolve) => setTimeout(resolve, 3000));
      console.log('req=', requestOptions);
      return fetch('/getPractice', requestOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log('fetch', data);
          const pdfRequestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              type: 'practice',
              return_url: '',
              exercises: data,
            }),
          };
          return fetch('/exercise-pdf', pdfRequestOptions);
        })
        .then((pdfResponse) => pdfResponse.blob()) // Get the binary data as a blob
        .then((blobData) => {
          // Create a downloadable URL for the blob data
          const url = URL.createObjectURL(blobData);
          const newTab = window.open(url, '_blank');
          return newTab;

          // Create a temporary anchor element and set its href to the downloadable URL
          // const a = document.createElement("a");
          // a.href = url;

          // // Set the filename for the downloaded file (optional)
          // const timestamp = new Date().toISOString().replace(/[:.]/g, "-");

          // a.download = `exercise_${timestamp}.pdf`;

          // // Programmatically trigger the anchor element's click event to initiate the download
          // a.click();

          // // Clean up by revoking the object URL
          // URL.revokeObjectURL(url);

          // return true; // Optional: Return a value indicating the download is successful
        })
        .catch((err) => {
          console.log('caught it!', err);
        })
        .finally(() => {
          setLoading(false);
          setSuccess(true);
        });
    } else {
      // Handle loading scenario
      // For example, show a loader or disable the button
      // until the previous request is completed.
      return Promise.resolve(); // Resolve with an empty Promise
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ m: 1, position: 'relative' }}>
        <Fab
          aria-label="save"
          color="primary"
          sx={buttonSx}
          onClick={handleButtonClick}
          disabled={disabled}
        >
          {success ? <CheckIcon /> : <SaveIcon />}
        </Fab>
        {loading && (
          <CircularProgress
            size={68}
            sx={{
              color: green[500],
              position: 'absolute',
              top: -6,
              left: -6,
              zIndex: 1,
            }}
          />
        )}
      </Box>
    </Box>
  );
}
