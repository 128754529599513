import React, { useEffect, useState } from 'react';
import 'katex/dist/katex.min.css';
import { InlineMath } from 'react-katex';
import './exercise_view.css';
import {
  Button,
  Checkbox,
  Chip,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { useParams } from 'react-router';
import { GradeSelect } from '../../components/gradeSelect';
import SubTopicSelect from '../../components/subTtopicSelect';
import { useSnackbar } from 'notistack';
import { saveExercise } from '../../dbOps/exercise';
import { useExercise } from '../../hooks/use-exercise';
import TopicSelect from '../../components/topicSelect';
import { useTranslation } from 'react-i18next';

export default function ExerciseView() {
  const [eq, setEq] = useState('\\frac{1}{2}+3\\frac{3}{2}=?');
  const [ans, setAns] = useState('5');
  const [tags, setTags] = useState<string[]>([]);
  const [curTag, setCurTag] = useState('');
  const [isForTest, setIsForTest] = useState<boolean>(false);
  const [isForRehearsal, setIsForRehearsal] = useState<boolean>(false);
  const [level, setLevel] = useState<string>('0');
  const [grade, setGrade] = useState('');
  const [topic, setTopic] = useState('');
  const [subTopic, setSubTopic] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { id, subTopicId } = useParams();
  console.log('exercise (id,subTopicId) = ', id, subTopicId);
  const exercise = useExercise(id, '');

  useEffect(() => {
    console.log(`updating ${JSON.stringify(exercise)}`);
    if (exercise.question === undefined) {
      return;
    }
    console.log('updating');

    const {
      question,
      answer,
      gradeId,
      level,
      topicId,
      subtopicId,
      tags,
      forTest,
      forRehearsal,
    } = exercise;
    console.log(`for rehearsal = ${forRehearsal}`);
    setEq(question);
    setAns(answer);
    setGrade(gradeId);
    setLevel(level);
    setTopic(topicId);
    setSubTopic(subtopicId);
    setTags(tags);
    setIsForTest(forTest);
    setIsForRehearsal(forRehearsal);
  }, [exercise]);

  // console.log(`exercise = ${JSON.stringify(exercise)}`);

  const handleSave = () => {
    const l: number = +level;
    console.log(`saving ${id}`);
    saveExercise(
      grade,
      topic,
      subTopic,
      eq,
      ans,
      l,
      tags,
      isForTest,
      isForRehearsal,
      id || '',
    );
    enqueueSnackbar('Saved', { variant: 'success' });
  };
  const handleGradeChange = (event: SelectChangeEvent) => {
    setGrade(event.target.value);
    console.log(grade);
  };

  const handleTopicChange = (event: SelectChangeEvent) => {
    setTopic(event.target.value);
  };
  const handleSubTopicChange = (event: SelectChangeEvent) => {
    setSubTopic(event.target.value);
  };
  const handleTagDelete = (tag: string) => {
    const tagsArr = tags.filter((item) => item !== tag);
    setTags(tagsArr);
  };
  const handleTagAdd = () => {
    if (curTag !== '') {
      const tagsArr = [curTag, ...tags];
      setTags(tagsArr);
      setCurTag('');
    }
  };
  return (
    <div className="exercise-view-container">
      <div>
        <a
          href="https://latex.codecogs.com/eqneditor/editor.php"
          target="_blank"
        >
          Go To Online Editor
        </a>
        <p>exercise id = {id}</p>
      </div>
      <div className="exercise-view-input">
        <TextField
          id="eq-input"
          label="Question"
          variant="outlined"
          value={eq}
          fullWidth
          onChange={(e) => setEq(e.target.value)}
          dir="ltr"
        />
      </div>
      <div className="exercise-view-preview">
        <InlineMath math={eq} />
      </div>
      <div className="exercise-view-input">
        <TextField
          id="eq-input"
          label="Answer"
          variant="outlined"
          value={ans}
          fullWidth
          onChange={(e) => setAns(e.target.value)}
          dir="ltr"
        />
      </div>
      <div className="exercise-view-preview">
        <InlineMath math={ans} />
      </div>
      <div className="exercise-metadata">
        <div className="metadata-item">
          <div className="metadata-tags-item">
            <TextField
              id="tag-input"
              label="Tag"
              variant="outlined"
              value={curTag}
              fullWidth
              onChange={(e) => setCurTag(e.target.value)}
            />
            <Button onClick={handleTagAdd}>Add</Button>
          </div>
          {tags.map((t, i) => (
            <Chip label={t} key={i} onDelete={() => handleTagDelete(t)} />
          ))}
        </div>
        <div className="metadata-item">
          <GradeSelect
            handleOnChange={handleGradeChange}
            value={grade}
          ></GradeSelect>
        </div>
        <div className="metadata-item">
          <TextField
            type="number"
            InputProps={{
              inputProps: {
                max: 100,
                min: 1,
              },
            }}
            label="level"
            value={level}
            onChange={(e) => setLevel(e.target.value)}
          />
        </div>
        <div className="metadata-item">
          <TopicSelect
            grade={grade}
            handleOnChange={handleTopicChange}
            topic={topic}
          ></TopicSelect>
        </div>
        <div className="metadata-item">
          <SubTopicSelect
            grade={grade}
            topic={topic}
            handleOnChange={handleSubTopicChange}
            sub_topic={subTopic}
          ></SubTopicSelect>
        </div>
        <div className="metadata-item">
          <Checkbox
            id="use-exe-for-test"
            checked={isForTest}
            onChange={() => setIsForTest(!isForTest)}
          />
          <label htmlFor="use-exe-for-test">{t('for exam')}</label>
        </div>
        <div className="metadata-item">
          <Checkbox
            id="use-exe-for-rehearsal"
            checked={isForRehearsal}
            onChange={() => setIsForRehearsal(!isForRehearsal)}
          />
          <label htmlFor="use-exe-for-rehearsal">{t('for rehearsal')}</label>
        </div>
        <div className="metadata-item">
          <Button variant="contained" onClick={handleSave}>
            שמור
          </Button>
        </div>
      </div>
    </div>
  );
}
