import { getDatabase, ref, onValue, off } from "firebase/database";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { setConfig } from "../store/config/config";
import { useMemo } from "react";
import { store } from "../store";

export function useConfig() {
    console.log("useConfig");

    useEffect(() => {
        const db = getDatabase();
        const starCountRef = ref(db, 'config/');
        
        onValue(starCountRef, (snapshot) => {
            const data = snapshot.val();
            console.log(data);
            store.dispatch(setConfig(data));
        });

        // Cleanup function
        return () => {
            off(starCountRef); // Unsubscribe from the Firebase listener
        };
    }, []); // Empty dependency array means this effect runs only once, on component mount

    const config = useSelector(
        (state: any) => state?.config.currentConfig || {}
    );

    return useMemo(() => config, [config]);
}
