import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';

// Define a type for the slice state
type ConfigState = {
	currentConfig: object;
};

// Define the initial state using that type
const initialState: ConfigState = {
	currentConfig: {}
};

export const configSlice: Slice<ConfigState> = createSlice({
	name: 'currentConfig',
	initialState,
	reducers: {
		setConfig: (state, action: PayloadAction<object>) => {
			state.currentConfig = action.payload;
		}
	}
});

export const { setConfig } = configSlice.actions;

export default configSlice.reducer;
