import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useNotifications } from '../../hooks/use-notifications';

type NotificationProps = {
  open: boolean;
  onClose: () => void;
};

const Notification: React.FC<NotificationProps> = ({ open, onClose }) => {
  const notifications = useNotifications();

  const handleNotificationClick = (id: string) => {
    // Add logic to mark the notification as read (update in the database or Redux state)
    console.log(`Notification with ID ${id} clicked`);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Mailbox</DialogTitle>
      <DialogContent>
        {notifications.length > 0 ? (
          <List>
            {notifications.map((notification) => (
              <ListItem
                key={notification.id}
                button
                onClick={() => handleNotificationClick(notification.id)}
              >
                <ListItemText
                  primary={notification.message}
                  secondary={notification.read ? 'Read' : 'Unread'}
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <DialogContentText>No new notifications</DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Notification;
