import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { RootState } from "../store";
export const useWaitingForReview = () => {
  const user = useSelector((state: any) => state.firebase.auth);

  useFirestoreConnect(() => {
    if (!user?.uid) return [];
    return [
      {
        collectionGroup: "tests",
        storeAs: "testForReview",
        where: ["status", "!=", "done"],
      },
    ];
  });

  const testForReview = useSelector(({ firestore: { data } }: RootState) => {
    return data?.testForReview || {};
  });

  return useMemo(
    () =>
      Object.entries(testForReview).map(([id, testForReview]: any) => {
        return { id, ...testForReview };
      }),
    [testForReview]
  );
};
