import { Avatar, IconButton } from '@mui/material';
import './addChildButton.css';
import ChildSvg from '../../../assets/icons/avatars/plus-student.svg';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export default function AddChildButton({ disabled }: any) {
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();

	return (
		<div className="addChild">
			<IconButton
				aria-label="settings"
				onClick={() => {
					disabled
						? enqueueSnackbar(
								t(
									'You have reached the maximum number of students, please contact us for additional students'
								),
								{
									variant: 'error',
									autoHideDuration: 3000
								}
							)
						: navigate('/new');
				}}
			>
				<Avatar alt="" src={ChildSvg} sx={{ width: 150, height: 150 }} />
			</IconButton>
			{t('Add Student')}
		</div>
	);
}
