import { configureStore } from '@reduxjs/toolkit';
import { firebase } from '../firebase';
import { createFirestoreInstance, firestoreReducer } from 'redux-firestore'; // <- needed if using firestore

import { FirestoreReducer, FirebaseReducer, firebaseReducer } from 'react-redux-firebase';
import userClaims from './userClaims/userClaims';
import { studentSlice } from './student/student';
import { configSlice } from './config/config';

const rrfConfig = {
	userProfile: 'users',
	useFirestoreForProfile: true,
	enableClaims: true, // Get custom claims along with the profile
	attachAuthIsReady: true // attaches auth is ready promise to store
};

export type RootState = {
	firebase: FirebaseReducer.Reducer;
	firestore: FirestoreReducer.Reducer;
	userClaims: typeof userClaims;
	student: typeof studentSlice.reducer;
	config: typeof configSlice.reducer;
};

const store = configureStore({
	reducer: {
		firebase: firebaseReducer,
		firestore: firestoreReducer,
		userClaims: userClaims,
		student: studentSlice.reducer,
		config: configSlice.reducer
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false
		})
});
const rrfProps = {
	firebase,
	config: rrfConfig,
	dispatch: store.dispatch,
	createFirestoreInstance // <- needed if using firestore
};

export { store, rrfProps };
