import { db, firebase } from "../firebase";

function saveExercise(
  grade: string,
  topic: string,
  subTopic: string,
  katex_string_q: string,
  katex_string_a: string,
  level: number,
  tags: Array<string>,
  isForTest: boolean,
  isForRehearsal: boolean,
  id: string
) {
  console.log(
    JSON.stringify({
      question: katex_string_q,
      answer: katex_string_a,
      gradeId: grade,
      topicId: topic,
      subtopicId: subTopic,
      level: level,
      tags: tags,
      forTest: isForTest,
      forRehearsal: isForRehearsal,
      id: firebase.firestore.FieldPath.documentId(),
    })
  );
  if (id !== "") {
    console.log(`updating exe ${id}`);
    return db
      .collection("exercisesPerTopic")
      .doc(subTopic)
      .collection(subTopic)
      .doc(id)
      .update({
        question: katex_string_q,
        answer: katex_string_a,
        gradeId: grade,
        topicId: topic,
        subtopicId: subTopic,
        level: level,
        tags: tags,
        forTest: isForTest,
        forRehearsal: isForRehearsal,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
  } else {
    console.log("adding new exe");
    return db
      .collection("exercisesPerTopic")
      .doc(subTopic)
      .collection(subTopic)
      .add({
        question: katex_string_q,
        answer: katex_string_a,
        gradeId: grade,
        topicId: topic,
        subtopicId: subTopic,
        level: level,
        tags: tags,
        forTest: isForTest,
        forRehearsal: isForRehearsal,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
  }
}

function deleteExercise(id: string, subTopic: string) {
  return db
    .collection("exercisesPerTopic")
    .doc(subTopic)
    .collection(subTopic)
    .doc(id)
    .delete();
}
export { saveExercise, deleteExercise };
