import { Avatar, Grid } from "@mui/material";
import { useNavigate } from "react-router";
import AddChildButton from "../../components/child/addButton/addChildButton";
import { useFamily } from "../../hooks/use-family";
import ChildSvg from "../../assets/icons/avatars/child.svg";
import "./family.css";
import { store } from "../../store";
import { setStudent } from "../../store/student/student";

function Family() {
  const family = useFamily();
  const navigate = useNavigate();

  return (
    <div className="family">
      <Grid container spacing={1} className="family-container">
        {family &&
          family.map((member) => (
            <Grid item key={member?.id} xs={12} sm={3}>
              <div className="child-container">
                <Avatar
                  alt={member.name}
                  sx={{ width: 150, height: 150 }}
                  src={member.avatar ? member.avatar : ChildSvg}
                  onClick={() => {
                    store.dispatch(setStudent(member)); // Use the setMember function
                    navigate(`/student/${member?.id}`);
                  }}
                />
                {member.name}
              </div>
            </Grid>
          ))}
        <Grid item key="new" xs={12} sm={3}>
          <div className="child-container">
            <AddChildButton disabled={family.length > 8} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Family;
