// import ReactGA from "react-ga";
import ReactGA from 'react-ga4';

const TRACKING_ID = 'G-QVED3HTP0N';

type Event = {
  name: string;
};

export enum Events {
  LOGIN = 'login',
  ADD_STUDENT = 'add_student',
  DOWNLOAD_TEST = 'download_test',
  UPLOAD_TEST_RESULT = 'upload_test_result',
}

const events: Record<Events, Event> = {
  [Events.LOGIN]: { name: 'login' },
  [Events.ADD_STUDENT]: { name: 'add_student' },
  [Events.DOWNLOAD_TEST]: { name: 'download_test' },
  [Events.UPLOAD_TEST_RESULT]: { name: 'upload_test_result' },
};

function init() {
  // Enable debug mode on the local development environment
  ReactGA.initialize(TRACKING_ID);
}

function sendEvent(
  id: string,
  category: string,
  action: Events,
  value: number,
) {
  ReactGA.event({
    category: category,
    action: events[action].name,
    value: value,
    nonInteraction: true, // optional, true/false
    transport: 'xhr', // optional, beacon/xhr/image
  });
}

function sendPageview(path: any) {
  ReactGA.send({ hitType: 'pageview', page: path });
}

export default {
  init,
  sendEvent,
  sendPageview,
};
