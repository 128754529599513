import { db, fieldValue, firebase } from "../firebase";

function AddNewStudent(
  name: string,
  email: string,
  phone: string,
  gradeId: string,
  avatarUrl: string,
  user: any
) {
  db.collection("users").doc(user.uid).collection("children").add({
    name: name,
    email: email,
    phone: phone,
    grade: gradeId,
    parentId: user.uid,
    avatar: avatarUrl,
    selected_topics: {},
    createdAt: fieldValue.serverTimestamp(),
    updatedAt: fieldValue.serverTimestamp(),
  });
}

function UpdateStudent(
  userId: string,
  name: string,
  phone: string,
  grade: string,
  studentId: string
) {
  db.collection("users")
    .doc(userId)
    .collection("children")
    .doc(studentId)
    .update({
      name: name,
      phone: phone,
      grade: grade,
      updatedAt: fieldValue.serverTimestamp(),
    });
}

function ResetUserProgress(userId: string, studentId: string) {
  db.collection("users")
    .doc(userId)
    .collection("children")
    .doc(studentId)
    .update({
      selected_topics: {},
      updatedAt: fieldValue.serverTimestamp(),
    });
}

function SetStudentDate(
  userId: string,
  studentId: string,
  date: firebase.firestore.Timestamp
) {
  db.collection("users")
    .doc(userId)
    .collection("children")
    .doc(studentId)
    .update({
      createdAt: date,
      updatedAt: date,
    });
}

export { AddNewStudent, UpdateStudent, ResetUserProgress, SetStudentDate };
