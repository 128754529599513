import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { RootState } from '../store';

export function useCurrentUser() {
  const user = useSelector((state: any) => state.firebase.auth);

  useFirestoreConnect(() => {
    if (!user?.uid) return [];
    return [
      {
        collection: 'users',
        doc: user.uid,
        storeAs: 'currentUser',
      },
    ];
  });

  const currentUser = useSelector(
    ({ firestore: { data } }: RootState) => data?.currentUser || {},
  );

  return useMemo(() => currentUser, [currentUser]);
}
