import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { RootState } from "../store";
export const useExercise = (id: any, subTopic: any) => {
  const user = useSelector((state: any) => state.firebase.auth);

  useFirestoreConnect(() => {
    if (!user?.uid) return [];
    return [
      {
        collectionGroup: subTopic,
        where: [
          "__name__",
          "==",
          `/exercisesPerTopic/${subTopic}/${subTopic}/${id}`,
        ],
        storeAs: "curExercise",
      },
    ];
  });

  const exercise = useSelector(({ firestore: { data } }: RootState) => {
    return data?.curExercise || {};
  });

  return useMemo(() => {
    return { ...exercise[id] };
  }, [exercise]);
};
