import React from 'react';

type MissingAssetIndicatorProps = {
  exist: boolean;
  label: string;
};

export default function MissingAssetIndicator(
  params: MissingAssetIndicatorProps,
) {
  return (
    <div>
      <p>{params.label}</p>
      {params.exist ? (
        <div
          style={{
            width: '20px',
            height: '20px',
            backgroundColor: 'green',
          }}
        ></div>
      ) : (
        <div
          style={{ width: '20px', height: '20px', backgroundColor: 'red' }}
        ></div>
      )}
    </div>
  );
}
