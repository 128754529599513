import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

import { db } from '../firebase';
import './selectTopics.css';
import { RadioGroup } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

//in case of change need to update i18n.json
enum Progress {
  NOT_SELECTED = 'not selected',
  BEGINNER = 'beginner',
  INTERMEDIATE = 'intermediate',
  ADVANCED = 'advanced',
  COMPLETED = 'completed',
}

enum Styles {
  NOT_SELECTED = 'progress-not-selected',
  SELECTED = 'progress-selected',
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export type DialogTopicSelectProps = {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
};

function BootstrapTopicSelectDialog(props: DialogTopicSelectProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

function TeacherSelectSubTopicDialog(params: any) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('');
  const { student, subTopic, index } = params;
  const { t } = useTranslation();

  const progress_types = Object.values(Progress);
  const admin = useSelector(
    (state: any) =>
      state.userClaims?.claims?.admin || state.userClaims?.claims?.teacher,
  );

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const handleClickOpen = () => {
    if (!admin) return;
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = () => {
    const markers_value =
      subTopic.topic_id in student.selected_topics
        ? {
            ...(student.selected_topics[subTopic.topic_id][subTopic.id]
              ?.markers || {}),
          }
        : {};
    const ended_date =
      subTopic.topic_id in student.selected_topics
        ? student.selected_topics[subTopic.topic_id][subTopic.id]?.ended_date ||
          null
        : null;
    markers_value[value] = markers_value[value] || null;
    db.collection('users')
      .doc(student.parentId)
      .collection('children')
      .doc(student.id)
      .update({
        [`selected_topics.${subTopic.topic_id}.${subTopic.id}`]: {
          status: value,
          subtopic_name: subTopic.name,
          topic_id: subTopic.topic_id,
          topic_name: subTopic.topic,
          markers: { ...markers_value },
          ended_date: ended_date,
        },
      });

    setOpen(false);
  };

  const getSelectionCssClass = (topic_progress: any) => {
    if (!topic_progress) return Styles.NOT_SELECTED;
    if (
      (subTopic.topic_id in student.selected_topics &&
        subTopic.id in student.selected_topics[subTopic.topic_id] &&
        topic_progress == null) ||
      topic_progress === Progress.NOT_SELECTED
    )
      return Styles.NOT_SELECTED;
    if (topic_progress === Progress.BEGINNER) return Styles.SELECTED;
    if (topic_progress === Progress.INTERMEDIATE) return Styles.SELECTED;
    if (topic_progress === Progress.ADVANCED) return Styles.SELECTED;
    if (topic_progress === Progress.COMPLETED) return Styles.SELECTED;
  };
  const getSelectionCssFill = (topic_progress: any) => {
    if (!topic_progress || topic_progress === Progress.NOT_SELECTED)
      return { '--progress-fill': `0%` } as React.CSSProperties;
    if (topic_progress === Progress.BEGINNER)
      return { '--progress-fill': `15%` } as React.CSSProperties;
    if (topic_progress === Progress.INTERMEDIATE)
      return { '--progress-fill': `50%` } as React.CSSProperties;
    if (topic_progress === Progress.ADVANCED)
      return { '--progress-fill': `75%` } as React.CSSProperties;
    if (topic_progress === Progress.COMPLETED)
      return { '--progress-fill': `100%` } as React.CSSProperties;
  };
  return (
    <div className="topic-select-button">
      <Button onClick={handleClickOpen}>
        <div
          className={`${getSelectionCssClass(
            subTopic.topic_id in student.selected_topics &&
              (
                student.selected_topics[subTopic.topic_id][subTopic.id] ??
                Progress.NOT_SELECTED
              ).status!,
          )} ${subTopic.id}-progress`}
          style={getSelectionCssFill(
            subTopic.topic_id in student.selected_topics &&
              (
                student.selected_topics[subTopic.topic_id][subTopic.id] ??
                Progress.NOT_SELECTED
              ).status,
          )}
        >
          <div className="topic-progress">{index + 1}</div>
        </div>
      </Button>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapTopicSelectDialog
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {subTopic?.name}
        </BootstrapTopicSelectDialog>
        <DialogContent dividers>
          <Typography gutterBottom>{subTopic?.description}</Typography>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={
              subTopic.topic_id in student.selected_topics
                ? student.selected_topics[subTopic.topic_id][subTopic.id]
                    ?.status || Progress.NOT_SELECTED
                : Progress.NOT_SELECTED
            }
            name="radio-buttons-group"
            onChange={handleRadioChange}
          >
            {progress_types.map((progress_type) => {
              return (
                <FormControlLabel
                  key={progress_type}
                  value={progress_type}
                  control={<Radio />}
                  label={t(progress_type)}
                />
              );
            })}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleSelect}>
            שמור
          </Button>
          <Button autoFocus onClick={() => setOpen(false)}>
            סגור
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

// function UserSelectTopicDialog(params: any) {
//   const [open, setOpen] = React.useState(false);
//   const { student, topic, index } = params;

//   const handleClickOpen = () => {
//     setOpen(true);
//   };
//   const handleClose = () => {
//     setOpen(false);
//   };

//   const handleRemove = () => {
//     db.collection("users")
//       .doc(student.parentId)
//       .collection("children")
//       .doc(student.id)
//       .update({
//         [`selected_topics.${topic.id}`]: "לא בלמידה",
//       });

//     setOpen(false);
//   };

//   const handleSelect = () => {
//     db.collection("users")
//       .doc(student.parentId)
//       .collection("children")
//       .doc(student.id)
//       .update({
//         [`selected_topics.${topic.id}`]: "מתחיל",
//       });

//     setOpen(false);
//   };

//   return (
//     <div className="topic-select-button">
//       <Button onClick={handleClickOpen}>
//         <div
//           className={` ${
//             student.selected_topics &&
//             topic.id in student.selected_topics &&
//             student.selected_topics[topic.id] !== "לא בלמידה"
//               ? "progress-bubble-selected"
//               : "progress-bubble"
//           }`}
//         >
//           <div className="topic-progress">{index + 1}</div>
//         </div>
//       </Button>

//       <BootstrapDialog
//         onClose={handleClose}
//         aria-labelledby="customized-dialog-title"
//         open={open}
//       >
//         <BootstrapTopicSelectDialog
//           id="customized-dialog-title"
//           onClose={handleClose}
//         >
//           {topic?.name}
//         </BootstrapTopicSelectDialog>
//         <DialogContent dividers>
//           <Typography gutterBottom>{topic?.description}</Typography>
//         </DialogContent>
//         <DialogActions>
//           <Button autoFocus onClick={handleSelect}>
//             בחר
//           </Button>
//           <Button autoFocus onClick={handleRemove}>
//             הסר
//           </Button>
//         </DialogActions>
//       </BootstrapDialog>
//     </div>
//   );
// }

export default function SelectSubTopicDialog(params: any) {
  return TeacherSelectSubTopicDialog(params);
  // const admin = useSelector((state: any) => state.userClaims?.claims?.admin);
  // if (admin) {
  //   return TeacherSelectSubTopicDialog(params);
  // } else {
  //   return UserSelectTopicDialog(params);
  // }
}
