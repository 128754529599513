import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

import {
  Button,
  Chip,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { storage, db } from '../../firebase';
import { makeStyles } from '@material-ui/core/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { AssetType } from '../../types/gradeAsset';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    direction: 'rtl',
    padding: theme.spacing(2),
    width: '500px',
    height: '800px',
  },
  '& .MuiDialogActions-root': {
    direction: 'rtl',
    padding: theme.spacing(1),
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  inputContainer: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'right',
    direction: 'rtl',
  },
  labelInputContainer: {
    display: 'flex',
    spaceBetween: '10px',
    flexDirection: 'row',
    alignItems: 'right',
    marginBottom: theme.spacing(2),
  },

  fileInput: {
    display: 'none',
    width: '100px',
  },
  button: {
    marginTop: theme.spacing(2),
    width: '100px',
  },
}));
export default function GradeAssetsUploader({ grade, asset_id }: any) {
  if (asset_id) {
    console.log(asset_id);
  }
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = React.useState<File | null>(null);
  const [progress, setProgress] = useState<number>(0);
  const [linkUrl, setLinkUrl] = useState<string>('');
  const [labels, setLabels] = useState<string[]>([]);
  const [type, setType] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [curLabel, setCurLabel] = useState<string>('');

  const handleLabelDelete = (label: string) => {
    const labelsArr = labels.filter((item: any) => item !== label);
    setLabels(labelsArr);
    setCurLabel('');
  };

  const handleAddLabel = () => {
    setLabels([...labels, curLabel]);
    setCurLabel('');
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const handleUpload = () => {
    if (file) {
      const uploadTask = storage
        .ref(`grades/${grade}/assets/${file.name}`)
        .put(file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
          );
          setProgress(progress);
        },
        (error) => {
          console.error(error);
        },
        () => {
          // Upload complete
          storage
            .ref(`grades/${grade}/assets/${file.name}`)
            .getDownloadURL()
            .then((url) => {
              // Save file URL and other document fields to Firestore
              db.collection(`grades/${grade}/assets`)
                .add({
                  fileName: file.name,
                  url: url,
                  description: description,
                  type: type,
                  labels: labels,
                  grade: grade,
                })
                .then(() => {
                  console.log('Document successfully added to Firestore');
                  handleClose();
                })
                .catch((error) => {
                  console.error('Error adding document: ', error);
                });
            });
        },
      );
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        הוסף חדש
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, direction: 'rtl' }}
          id="customized-dialog-title"
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              float: 'left',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          העלאת קבצים
        </DialogTitle>

        <DialogContent dividers>
          <div className={classes.inputContainer}>
            <InputLabel id="type-select">Type</InputLabel>
            <Select
              labelId="type-select"
              id="type-select"
              value={type}
              label="Type"
              onChange={(e) => {
                setType(e.target.value as string);
              }}
            >
              <MenuItem value={AssetType.EXAM_1}>מבחן ראשון</MenuItem>
              <MenuItem value={AssetType.EXAM_2}>מבחן אמצע</MenuItem>
              <MenuItem value={AssetType.EXAM_3}>מבחן סוף</MenuItem>
              <MenuItem value={AssetType.DOCUMENT}>מסמך </MenuItem>
              <MenuItem value={AssetType.LINK}>לינק</MenuItem>
              <MenuItem value={AssetType.VIDEO}>וידאו</MenuItem>
            </Select>
          </div>
          <div className={classes.inputContainer}>
            <TextField
              id="description"
              label="תיאור"
              variant="outlined"
              fullWidth
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </div>
          <div className={classes.inputContainer}>
            <TextField
              id="link"
              label="לינק"
              variant="outlined"
              fullWidth
              value={linkUrl}
              onChange={(e) => {
                setLinkUrl(e.target.value);
              }}
            />
          </div>
          <div className={classes.labelInputContainer}>
            <TextField
              id="labels"
              label="label"
              variant="outlined"
              value={curLabel}
              onChange={(e) => {
                setCurLabel(e.target.value);
              }}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={handleAddLabel}
            >
              Add
            </Button>
          </div>
          <div>
            {labels.map((t, i) => (
              <Chip
                label={t}
                key={i}
                onDelete={() => handleLabelDelete(t)}
                style={{ margin: '5px' }}
              />
            ))}
          </div>
          <div>
            <Button
              component="label"
              role={undefined}
              variant="contained"
              color="primary"
              tabIndex={-1}
            >
              <CloudUploadIcon />
              Select file
              <input
                type="file"
                onChange={handleFileChange}
                className={classes.fileInput}
              />
            </Button>
            {file && <p>{file.name}</p>}

            {progress > 0 && <p>Progress: {progress}%</p>}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            component="span"
            className={classes.button}
            onClick={handleUpload}
          >
            Save
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
