import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { RootState } from "../store";

export const useSubTopics = (gradeId: string, topicId: string) => {
  const user = useSelector((state: any) => state.firebase.auth);

  useFirestoreConnect(() => {
    if (!user?.uid || topicId === "") return [];
    return [
      {
        collection: "topics",
        doc: gradeId,
        subcollections: [
          {
            collection: "sub_topics",
            where: ["topic_id", "==", topicId],
          },
        ],
        storeAs: "subTopics",
      },
    ];
  });

  const subTopics = useSelector(({ firestore: { data } }: RootState) => {
    return data?.subTopics || {};
  });

  return useMemo(
    () =>
      Object.entries(subTopics).map(([id, topic]: any) => {
        return { id, ...topic };
      }),
    [subTopics]
  );
};
