import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { RootState } from "../store";

export function useStudentTests(studentId: string) {
  const user = useSelector((state: any) => state.firebase.auth);

  useFirestoreConnect(() => {
    if (!user?.uid) return [];
    return [
      {
        collection: "students",
        doc: studentId,
        subcollections: [{ collection: "tests" }],
        storeAs: "studentTests",
      },
    ];
  });

  const studentTests = useSelector(
    ({ firestore: { data } }: RootState) => data?.studentTests || {}
  );

  return useMemo(
    () =>
      Object.entries(studentTests).map(([id, child]: any) => {
        return { id, ...child };
      }),
    [studentTests]
  );
}
