import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { RootState } from "../store";
import { useMemo } from "react";
export const useTopic = (gradeId: string, topicId: string) => {
  const user = useSelector((state: any) => state.firebase.auth);

  useFirestoreConnect(() => {
    if (!user?.uid) return [];
    if (gradeId === "") return [];
    return [
      {
        collection: "topics",
        doc: gradeId,
        subcollections: [{ collection: "topics", doc: topicId }],
        storeAs: "myTopic",
      },
    ];
  });

  const topic = useSelector(({ firestore: { data } }: RootState) => {
    return data?.myTopic || {};
  });
  // return { gradeId, topicId, ...topic };

  return useMemo(() => {
    return { gradeId, topicId, ...topic };
  }, [gradeId, topicId, topic]);
};
