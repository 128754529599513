import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state
type UserClaimsState = {
  claims: object;
};

// Define the initial state using that type
const initialState: UserClaimsState = {
  claims: {},
};

export const userClaimsSlice = createSlice({
  name: 'userClaims',
  initialState,
  reducers: {
    setUserClaims: (state, action: PayloadAction<object>) => {
      // console.log(`action = ${JSON.stringify(action.payload)}`);
      state.claims = action.payload;
    },
  },
});

export const { setUserClaims } = userClaimsSlice.actions;

export default userClaimsSlice.reducer;
