import { useState, useRef, useLayoutEffect } from 'react';

export function useImage(
	url: string | null,
	crossOrigin: string | undefined,
	referrerPolicy: string | undefined,
	version: number | undefined
): [HTMLImageElement | undefined, string] {
	const statusRef = useRef<string>('loading');
	const imageRef = useRef<HTMLImageElement | undefined>();
	const [ , setStateToken ] = useState<number>(0);

	const oldUrl = useRef<string | null>(null);
	const oldVersion = useRef<number | undefined>(undefined);
	const oldCrossOrigin = useRef<string | undefined>(undefined);
	const oldReferrerPolicy = useRef<string | undefined>(undefined);

	if (
		oldUrl.current !== url ||
		oldCrossOrigin.current !== crossOrigin ||
		oldReferrerPolicy.current !== referrerPolicy ||
		oldVersion.current !== version
	) {
		statusRef.current = 'loading';
		imageRef.current = undefined;
		oldUrl.current = url;
		oldCrossOrigin.current = crossOrigin;
		oldReferrerPolicy.current = referrerPolicy;
		oldVersion.current = version;
	}

	useLayoutEffect(
		() => {
			if (!url) return;

			const img = document.createElement('img');

			function onload() {
				statusRef.current = 'loaded';
				imageRef.current = img;
				setStateToken(Math.random());
			}

			function onerror() {
				statusRef.current = 'failed';
				imageRef.current = undefined;
				setStateToken(Math.random());
			}

			img.addEventListener('load', onload);
			img.addEventListener('error', onerror);

			if (crossOrigin) {
				img.crossOrigin = crossOrigin;
			}

			if (referrerPolicy) {
				img.referrerPolicy = referrerPolicy;
			}

			img.src = `${url}&v=${version}`; // cache buster

			return function cleanup() {
				img.removeEventListener('load', onload);
				img.removeEventListener('error', onerror);
			};
		},
		[ url, crossOrigin, referrerPolicy ]
	);

	return [ imageRef.current, statusRef.current ];
}
