import './App.css';
import MiniDrawer from './components/drawer';
import { Route, Routes } from 'react-router';
import {
  IRoute,
  pagesRoutes,
  ProtectedAdminRoute,
  ProtectedAuthRoute,
} from './routes/router';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { useSelector } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import useGoogleAnalytics from './hooks/use-analytics';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';

// import moment
import moment from 'moment';
// import moment lang support
import 'moment/locale/he';
import { useEffect } from 'react';
import { getUserIdToken } from './firebase';
import { setUserClaims } from './store/userClaims/userClaims';
import firebase from 'firebase/compat/app';
import Footer from './components/footer/footer';
import { AnyAction } from '@reduxjs/toolkit';

//set moment language
moment.locale('he');

function App() {
  const dispatch: Dispatch<AnyAction> = useDispatch();

  const user = useSelector((state: any) => state.firebase.auth);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      console.log(window.location.pathname);
      if (user) {
        // navigate("/");
      } else if (window.location.pathname !== '/login') {
        // navigate("/landing");
      }
    });
  }, []);
  useEffect(() => {
    getUserIdToken()
      .then((idTokenResult: any) => {
        dispatch(setUserClaims(idTokenResult?.claims));
      })
      .catch((error: any) => {
        console.log(error);
      });
  }, [user]);

  useGoogleAnalytics();

  return (
    <ThemeProvider theme={theme}>
      <div className="App" dir="rtl">
        <SnackbarProvider
          maxSnack={4}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          autoHideDuration={2000}
        >
          <div className="App-header">
            <MiniDrawer></MiniDrawer>
          </div>
          <div className="App-content">
            <Routes>
              {pagesRoutes.map((route: IRoute, index: number) =>
                route.adminGuard ? (
                  <Route
                    key={index}
                    path={route.path}
                    element={
                      <ProtectedAdminRoute>{route.element}</ProtectedAdminRoute>
                    }
                  />
                ) : route.authGuard ? (
                  <Route
                    key={index}
                    path={route.path}
                    element={
                      <ProtectedAuthRoute>{route.element}</ProtectedAuthRoute>
                    }
                  />
                ) : (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.element}
                  />
                ),
              )}
            </Routes>
          </div>
          <Footer />
        </SnackbarProvider>
      </div>
    </ThemeProvider>
  );
}

export default App;
