import { db, firebase } from "../firebase";

enum TestStatus {
  Done = "הסתיים",
  Review = "בבדיקה",
  Sent = "נשלח",
}

function getStatusString(status: string) {
  switch (status) {
    case "done":
      return TestStatus.Done;
    case "review":
      return TestStatus.Review;
    case "sent":
      return TestStatus.Sent;
  }
}
function setTestStatusReview(
  student_id: string,
  test_id: string,
  checks: string[]
) {
  db.collection("students")
    .doc(student_id)
    .collection("tests")
    .doc(test_id)
    .update({ status: "review", checks: checks });
}
function setTestStatusDone(student_id: string, test_id: string, url: string) {
  db.collection("students")
    .doc(student_id)
    .collection("tests")
    .doc(test_id)
    .update({ status: "done", review_file: url });
}

function setTestStatusSent(
  student_id: string,
  test_id: string,
  exam_download_url: string
) {
  db.collection("students")
    .doc(student_id)
    .collection("tests")
    .doc(test_id)
    .update({ status: "sent", exam_file: exam_download_url });
}

function setExamUploadFiles(
  student_id: string,
  examId: string,
  downloads_urls: string[]
) {
  db.collection("students")
    .doc(student_id)
    .collection("tests")
    .doc(examId)
    .update({ uploads: downloads_urls, status: "sent" });
}

async function setNewExam(
  studentId: string,
  status: string,
  userId: string,
  data: any[]
) {
  const exam = {
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    status: status,
    data: data,
    userId: userId,
    studentId: studentId,
  };
  const docRef = await db
    .collection("students")
    .doc(studentId)
    .collection("tests")
    .add(exam);

  return docRef.id;
}

async function updateExamFileUrl(
  studentId: string,
  examId: string,
  url: string
) {
  return db
    .collection("students")
    .doc(studentId)
    .collection("tests")
    .doc(examId)
    .update({ exam_file: url });
}

export {
  TestStatus,
  getStatusString,
  setTestStatusReview,
  setTestStatusDone,
  setTestStatusSent,
  setExamUploadFiles,
  setNewExam,
  updateExamFileUrl,
};
