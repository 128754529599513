import React from 'react';
import { ColorPalette } from 'material-ui-color';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import SaveIcon from '@mui/icons-material/Save';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

import PencilIcon from '../../../assets/icons/pencil.svg';
import EraserIcon from '../../../assets/icons/eraser.svg';
import StudentIcon from '../../../assets/icons/student.svg';
import './toolbar.css';
import { useTranslation } from 'react-i18next';

type toolbarProps = {
  onSave: () => void;
  onResetImage: () => void;
  onUndo: () => void;
  onRedo: () => void;
  onNavigateTeacherView: () => void;
  onDoneCheck: () => void;
  onPageChange: (page: number) => void;
  onBrushColorChange: (color: string) => void;
  onToolChanged: (tool: string) => void;
  onBrushSizeChanged: (size: number) => void;
  pageCount: number;
  brushSize: string;
};

function ToolBar(props: toolbarProps) {
  const { t } = useTranslation();
  const palette = {
    red: '#ff0000',
    blue: '#0000ff',
    green: '#00ff00',
    yellow: 'yellow',
    black: 'black',
    //cyan: "cyan",
    //lime: "lime",
    //gray: "gray",
    //orange: "orange",
    //purple: "purple",
    //pink: "pink",
    //darkblue: "darkblue",
  };

  const brushSizes = Array(15)
    .fill(1)
    .map((x, y) => x + y);

  return (
    <div className="toolbox-container">
      <FormControl>
        <InputLabel id="brush-size-select">Brush Size</InputLabel>
        <Select
          labelId="brush-size-select"
          id="demo-simple-select"
          value={props.brushSize ?? '4'}
          label="Brush Size"
          onChange={(e) => {
            props.onBrushSizeChanged(parseInt(e.target.value as string));
          }}
        >
          {brushSizes.map((i: any) => (
            <MenuItem key={i} value={i}>
              {i}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        type="number"
        onChange={(e) => {
          props.onPageChange(parseInt(e.target.value) - 1);
        }}
        InputProps={{
          inputProps: {
            max: props.pageCount ?? 1,
            min: 1,
          },
        }}
        label="Page"
      />
      <IconButton
        onClick={(e: any) => {
          console.log(e);
          props.onSave();
          //   handleSave(sid as string, examId as string);
          //   setLines([]);
        }}
      >
        <SaveIcon />
      </IconButton>
      <IconButton onClick={props.onResetImage}>
        <RestartAltIcon />
      </IconButton>
      <IconButton onClick={props.onUndo}>
        <UndoIcon />
      </IconButton>
      <IconButton onClick={props.onRedo}>
        <RedoIcon />
      </IconButton>
      <IconButton
        onClick={() => {
          props.onToolChanged('eraser');
        }}
      >
        <img alt="eraser" src={EraserIcon} height={25} width={25}></img>
      </IconButton>
      <IconButton
        onClick={() => {
          props.onToolChanged('brush');
        }}
      >
        <img alt="pencil" src={PencilIcon} height={25} width={25}></img>
      </IconButton>
      <ColorPalette
        palette={palette}
        onSelect={(color: any) => {
          props.onBrushColorChange(color);
        }}
      />
      <IconButton
        onClick={() => {
          props.onNavigateTeacherView();
        }}
      >
        <img alt="student" src={StudentIcon} height={25} width={25}></img>
      </IconButton>
      <Button
        onClick={() => {
          props.onDoneCheck();
        }}
        variant="contained"
      >
        {t('send')}
      </Button>
    </div>
  );
}

ToolBar.propTypes = {};

export default ToolBar;
