import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import {
  ResetUserProgress,
  SetStudentDate,
} from '../../../dbOps/usersCollection';
import { useSnackbar } from 'notistack';
import { firebase } from '../../../firebase';

const useStyles = makeStyles((theme: any) => ({
  root: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  button: {
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    border: '2px solid #3f51b5',
    borderRadius: '5px',
    padding: '10px 20px',
    transition: 'all 0.3s ease',
    '&:hover': {
      backgroundColor: '#3f51b5',
      color: '#fff',
    },
  },
}));

// function extractSubtopics(user: any): string[] {
//   const subtopics: string[] = [];

//   for (const topicId in user.selected_topics) {
//     const topic = user.selected_topics[topicId];
//     for (const subtopicId in topic) {
//       if (topic[subtopicId].status !== 'not selected') {
//         subtopics.push(subtopicId);
//       }
//     }
//   }

//   return subtopics;
// }

// function getExercisesPdf(student: any) {
//   const subtopics = extractSubtopics(student);

//   const req: any = {
//     generator: 'manual',
//     studentId: student.id,
//     uid: student.parentId,
//     sub_topics: subtopics,
//   };
//   const requestOptions = {
//     method: 'POST',
//     headers: { 'Content-Type': 'application/json' },
//     body: JSON.stringify(req),
//   };
//   return fetch('/getPractice', requestOptions)
//     .then((response) => response.json())
//     .then((data) => {
//       const pdfRequestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({
//           type: 'practice',
//           return_url: '',
//           exercises: data,
//         }),
//       };
//       return fetch('/exercise-pdf', pdfRequestOptions);
//     })
//     .then((pdfResponse) => pdfResponse.blob())
//     .then((blobData) => {
//       const url = URL.createObjectURL(blobData);
//       const newTab = window.open(url, '_blank');
//       return newTab;
//       // const a = document.createElement("a");
//       // a.href = url;
//       // const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
//       // a.download = `exercise_${timestamp}.pdf`;
//       // a.click();
//       // URL.revokeObjectURL(url);
//       // return true;
//     });
// }

function Simulation() {
  const { enqueueSnackbar } = useSnackbar();

  const student = useSelector(
    (state: any) => state.student.currentStudent || {},
  );
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(
    dayjs('2024-01-17'),
  );

  const classes = useStyles();

  async function handleGenerateExercise(student: any) {
    const RequestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        uid: student.parentId,
        studentId: student.id,
      }),
    };
    const response = await fetch('/e/v1/getExam', RequestOptions);
    const urlText = await response.text();
    if (urlText) {
      window.open(urlText, '_blank');
    }

    console.log('response=', response);
  }

  function handleResetStudentProgress() {
    ResetUserProgress(student.parentId, student.id);
    enqueueSnackbar('התקדמות התלמיד אופסה בהצלחה', {
      variant: 'success',
    });
  }

  function handleSetStudentCreatedDate(): void {
    if (!selectedDate) return;
    const timestamp = firebase.firestore.Timestamp.fromDate(
      selectedDate.toDate(),
    );
    SetStudentDate(student.parentId, student.id, timestamp);
    console.log('handleSetStudentCreatedDate: ' + timestamp);
  }

  return (
    <div className={classes.root}>
      <Typography variant="h1" className={classes.header}>
        Simulation
      </Typography>
      <div>
        <div className={classes.buttonContainer}>
          <Button
            variant="outlined"
            className={classes.button}
            color="primary"
            onClick={handleGenerateExercise}
          >
            בחר תלמיד
          </Button>
        </div>
        <Typography variant="h2">שם תלמיד: {student.name}</Typography>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          variant="outlined"
          className={classes.button}
          color="primary"
          onClick={async () => {
            await handleGenerateExercise(student);
          }}
        >
          צור מבחן
        </Button>
        <Button
          variant="outlined"
          className={classes.button}
          color="primary"
          onClick={handleResetStudentProgress}
        >
          אפס התקדמות תלמיד
        </Button>
        <Button
          variant="outlined"
          className={classes.button}
          color="primary"
          onClick={handleGenerateExercise}
        >
          צור דף תרגול
        </Button>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          variant="outlined"
          className={classes.button}
          color="primary"
          onClick={handleSetStudentCreatedDate}
        >
          שנה תאריך יצירת תלמיד
        </Button>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker value={selectedDate} onChange={setSelectedDate} />
        </LocalizationProvider>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          variant="outlined"
          className={classes.button}
          color="primary"
          onClick={handleGenerateExercise}
        >
          מבחן תחילת שנה
        </Button>
        <Button
          variant="outlined"
          className={classes.button}
          color="primary"
          onClick={handleGenerateExercise}
        >
          מבחן אמצע שנה
        </Button>
        <Button
          variant="outlined"
          className={classes.button}
          color="primary"
          onClick={handleGenerateExercise}
        >
          מבחן סוף שנה
        </Button>
        <Button
          variant="outlined"
          className={classes.button}
          color="primary"
          onClick={handleGenerateExercise}
        >
          מבחן לפי תאריך
        </Button>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          variant="outlined"
          className={classes.button}
          color="primary"
          onClick={handleGenerateExercise}
        >
          שלח מייל
        </Button>
        <Button
          variant="outlined"
          className={classes.button}
          color="primary"
          onClick={handleGenerateExercise}
        >
          שלח הודעה
        </Button>
      </div>
    </div>
  );
}

export default Simulation;
