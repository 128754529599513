import React from 'react';
import { Grid, Paper, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AvailableGrades } from '../../../components/gradeSelect';
import './grades.css';

// Styling for the item
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  marginTop: 20,
  marginRight: 20,
  marginLeft: 20,
  height: 120,
  width: 120,
  lineHeight: '120px',
  alignContent: 'center',
}));

// Component for GradeConfigSelectPage
function GradeConfigSelectPage() {
  const navigate = useNavigate();

  // Function to handle item click
  const handleItemClick = (val: string) => {
    navigate(`/backoffice/grades/${val}`);
  };

  return (
    <div className="grade-select-page">
      <Grid container spacing={{ xs: 2, md: 8 }} direction="row">
        {Object.entries(AvailableGrades).map(([key, val]) => (
          <Grid item key={val} xs={3}>
            <Item elevation={16} onClick={() => handleItemClick(val)}>
              {key}
            </Item>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default GradeConfigSelectPage;
