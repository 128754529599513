import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { RootState } from '../store';

export function useStudentExam(studentId: string, examId: string) {
  const user = useSelector((state: any) => state.firebase.auth);
  console.log('studentId', studentId);
  console.log('examId', examId);

  useFirestoreConnect(() => {
    if (!user?.uid) return [];
    return [
      {
        collection: 'students',
        doc: studentId,
        subcollections: [
          { collection: 'tests', where: [['__name__', '==', examId]] },
        ],
        storeAs: 'examDocument',
      },
    ];
  });

  const examDocument = useSelector(
    ({ firestore: { data } }: RootState) => data?.examDocument || undefined,
  );

  return useMemo(() => {
    console.log('examDocument', examDocument);
    return examDocument !== undefined
      ? { id: examId, ...examDocument[examId] }
      : undefined;
  }, [examDocument, examId]);
}
