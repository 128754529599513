import { useGradeAssets } from '../../hooks/use-gradeAssets';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import GradeAssetsEdit from './gradeAssetsEdit';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'type',
    headerName: 'Type',
    width: 200,
    editable: true,
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 250,
    editable: true,
  },

  {
    field: 'url',
    headerName: 'URL',
    width: 200,
    editable: true,
  },
  {
    field: 'link',
    headerName: 'Link',
    width: 200,
    editable: true,
  },
  {
    field: 'edit',
    headerName: 'Edit',
    width: 100,
    renderCell: (params) => {
      return <GradeAssetsEdit grade={params.row.gradeId} id={params.row.id} />;
    },
  },
  {
    field: 'open',
    headerName: 'Open',
    width: 100,
    renderCell: (params) => {
      return (
        <IconButton
          onClick={() => window.open(params.row.url, '_blank', 'noopener')}
        >
          <OpenInBrowserIcon />
        </IconButton>
      );
    },
  },
  {
    field: 'delete',
    headerName: 'Delete',
    width: 100,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    renderCell: (params) => {
      return (
        <IconButton onClick={() => alert('delete')}>
          <DeleteIcon />
        </IconButton>
      );
    },
  },
];

export default function GradeAssetsView({ grade }: any) {
  const assets = useGradeAssets(grade);

  return (
    <div>
      <Box sx={{ height: '70vh', width: '100%' }}>
        <DataGrid
          rows={assets}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 15,
              },
            },
          }}
          pageSizeOptions={[15, 30, 50, 100]}
          checkboxSelection
          disableRowSelectionOnClick
        />
      </Box>
    </div>
  );
}
