import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { RootState } from "../store";
import { useMemo } from "react";
export const useSubTopic = (gradeId: string, subTopicId: string) => {
  const user = useSelector((state: any) => state.firebase.auth);

  useFirestoreConnect(() => {
    if (!user?.uid) return [];
    if (gradeId === "") return [];
    return [
      {
        collection: "topics",
        doc: gradeId,
        subcollections: [{ collection: "sub_topics", doc: subTopicId }],
        storeAs: "mySubTopic",
      },
    ];
  });

  const subTopic = useSelector(({ firestore: { data } }: RootState) => {
    return data?.mySubTopic || {};
  });
  // return { gradeId, topicId, ...topic };

  return useMemo(() => {
    return { gradeId, subTopicId, ...subTopic };
  }, [gradeId, subTopicId, subTopic]);
};
