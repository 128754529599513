import { InputLabel, MenuItem, Select } from "@mui/material";
import { useSubTopics } from "../hooks/use-subtopics";

function SubTopicSelect({ grade, topic, sub_topic, handleOnChange }: any) {
  console.log(grade);
  console.log(topic);
  console.log(sub_topic);

  const subTopics = useSubTopics(grade, topic);
  console.log(`subTopic=${subTopics}`);
  return (
    <div>
      <InputLabel id="topic-select-label">נושא</InputLabel>
      <Select
        labelId="topic-select-label"
        id="topic-select"
        value={sub_topic}
        onChange={handleOnChange}
        label="Topic"
      >
        {subTopics.map((t: any, i: any) => (
          <MenuItem value={t.id} key={i}>
            {t.name}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

export default SubTopicSelect;
