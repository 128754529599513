import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

import './footer.css';

const StyledFooter = styled('footer')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: '100%',
  bottom: 0,
}));

function Footer() {
  const { t } = useTranslation();

  const footerLinks = [
    { label: t('home'), href: '/' },
    { label: t('about'), href: '/about' },
    { label: t('contact'), href: '/contact' },
    { label: t('privacy'), href: '/privacy-policy' },
    { label: t('terms'), href: '/terms-of-service' },
  ];

  return (
    <StyledFooter>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <p>&copy; 2023 grade-boost.com All rights reserved.</p>
          </div>
          <div className="col-md-6">
            <nav aria-label="Footer Navigation">
              <ul role="list">
                {footerLinks.map((link, index) => (
                  <li key={index} role="listitem">
                    <a href={link.href} aria-label={link.label}>
                      {link.label}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </StyledFooter>
  );
}

export default Footer;
